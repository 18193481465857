import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@material-ui/core';
import { useSelector } from 'store/hook';
import { statsRequest, checkRequest } from 'services/statistics/slice';
import { usePermissions } from 'components/Permissions/hooks';
import { Loading } from 'components/Loading';
import { TelemetryStats } from 'pages/StatisticsPage/TelemetryStats';
import classNames from 'classnames';
import { TenantItem } from 'services/tenants/types';
import { UnderConstruction } from './components/UnderConstruction';
import useStyles from './utils/useStyles';
import AssetsByStatusStats from './components/AssetsByStatusStats';
import ActiveIncidentsStats from './components/ActiveIncidentsStats';
import IncindetsStats from './components/IncidentsStats';
import ResponsesStats from './components/ResponsesStats';
import TenantsFilter from './components/TenantsFilter';
import AssetsStats from './components/AssetsStats';

export const StatsFilterTenantIds = 'StatsFilterTenantIds';

export const StatisticsPage: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation(['StatisticsPage']);
  const [viewLicensePerm] = usePermissions(['view_organization_license']);
  const dispatch = useDispatch();

  const { isLoading, isChecking, isAvailable, filterTenantIds } = useSelector(
    (state) => state.statistics,
  );
  const { list }: { list: TenantItem[] } = useSelector((state) => state.tenants);

  useEffect(() => {
    dispatch(checkRequest());
  }, [dispatch]);

  useEffect(() => {
    if (isAvailable) {
      dispatch(statsRequest({ tenantIds: filterTenantIds }));
    }
  }, [isAvailable, dispatch, filterTenantIds]);

  if (isChecking || isAvailable === null) {
    return <Loading />;
  }

  if (isAvailable === false) {
    return <UnderConstruction />;
  }

  return (
    <div>
      <div className={classes.root}>
        <div style={{ justifyContent: 'space-between', display: 'flex' }}>
          <div>
            <h1 className={classes.title}>{t('Summary')}</h1>
          </div>
          {list.length > 1 ? <TenantsFilter list={list} /> : null}
        </div>
      </div>
      <div className={classes.root}>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <div className={classes.row}>
              <div className={classNames(classes.cell, classes.cellSm)}>
                {viewLicensePerm && <AssetsStats />}
              </div>
              <div className={classNames(classes.cell, classes.cellLg)}>
                <div className={classes.chartContainer}>
                  <div className={classes.chartTile}>
                    <TelemetryStats />
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.row}>
              <div className={classNames(classes.cell, classes.cellSm)}>
                <div className={classes.chartContainer}>
                  <AssetsByStatusStats />
                  <ActiveIncidentsStats />
                </div>
              </div>
              <div className={classNames(classes.cell, classes.cellLg)}>
                <IncindetsStats />
              </div>
            </div>
            <div className={classes.row}>
              <ResponsesStats />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
