import { postRequest } from 'utils/http';
import { TelemetryStatsRequest, StatsRequest } from './types';

const url = 'statistics';

export function getStats({ tenantIds }: StatsRequest) {
  return postRequest(`${url}/stats`, { tenantIds });
}

export function getTelemetryStats({ dayRange, tenantIds }: TelemetryStatsRequest) {
  return postRequest(`${url}/stats/telemetry`, { dayRange, tenantIds });
}

export function getCheck() {
  return postRequest(`${url}/check`);
}
