import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { KLDrawer } from 'components/KLDrawer';
import { Box, Typography, useTheme } from '@material-ui/core';
import { Table } from 'components/Table';
import { useTranslation } from 'react-i18next';
import { IncidentIoc } from 'services/incidents/types';
import styles from './styles';

const useStyles = makeStyles(styles);

type IocDetailsDialogProps = {
  ioc: IncidentIoc;
  open: boolean;
  setIocDetailsVisible: (v: boolean) => void;
}

export const IocDetailsDialog = (props: IocDetailsDialogProps) => {
  const classes = useStyles();
  const { ioc, open, setIocDetailsVisible } = props;
  const { t } = useTranslation('IncidentDetailsPage');
  const theme = useTheme();

  const onClose = () => {
    setIocDetailsVisible(false);
  };

  return (
    <KLDrawer
      open={open}
      onClose={onClose}
      helpId="IocDetailsDialog"
      noPadding
      fullContentSpace
    >
      <Box className={classes.container}>
        <Box className={classes.title}>
          <Typography variant="h3" component="h3">
            {t('Properties')}
          </Typography>
        </Box>
        <Box className={classes.property}>
          <Typography className={classes.label}>{t('IOC value')}: </Typography>
          <Typography className={classes.value}>{ioc.data}</Typography>
        </Box>

        {Boolean(ioc.hosts?.length) && (
          <Box className={classes.property}>
            <Typography className={classes.label}>{t('Asset name')}: </Typography>
            <Typography className={classes.value}>{ioc?.hosts?.join(', ')}</Typography>
          </Box>
        )}

        {Boolean(ioc.message?.length) && (
          <Box className={classes.property}>
            <Typography className={classes.label}>{t('Description')}: </Typography>
            <Typography className={classes.value}>{ioc.message}</Typography>
          </Box>
        )}

        {(Boolean(ioc.hostPaths?.length) && ioc.hostPaths) && (
          <Box className={classes.property}>
            <Typography className={classes.label}>{t('Detail information')}: </Typography>
            <Box className={classes.property}>
              <Table
                columns={[
                  {
                    title: t('Path'),
                    field: 'path',
                    cellStyle: {
                      maxWidth: 250,
                      whiteSpace: 'normal',
                      wordWrap: 'break-word',
                    },
                    headerStyle: { paddingLeft: theme.spacing(2) },
                  },
                  {
                    cellStyle: {
                      maxWidth: 150,
                      whiteSpace: 'normal',
                      wordWrap: 'break-word',
                    },
                    title: t('Asset name'),
                    render: ({ assets }) => assets
                      .map(({ computerName }: { computerName: string }) => computerName).join(', '),
                    headerStyle: { paddingLeft: theme.spacing(2) },
                  },
                ]}
                data={ioc.hostPaths}
                count={ioc.hostPaths?.length ?? 0}
                options={{
                  search: false,
                  showTitle: false,
                  toolbar: false,
                  sorting: false,
                  paging: false,
                  headerStyle: {
                    fontWeight: 700,
                    borderBottom: 'none',
                    padding: theme.spacing(0.5, 1),
                    lineHeight: theme.typography.pxToRem(32),
                    whiteSpace: 'nowrap',
                  },
                }}
              />
            </Box>
          </Box>
        )}
      </Box>
    </KLDrawer>
  );
};
