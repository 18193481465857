import React, { useState, useMemo, useCallback, ChangeEvent, useEffect, FC } from 'react';
import { makeStyles, Badge, IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FilterIcon } from 'assets/icons/FilterIcon';
import { TenantItem } from 'services/tenants/types';
import { KLCheckBox } from 'components/KLCheckBox';
import { KLButton } from 'components/KLButton';
import { load, save, remove } from 'utils/storage';
import { StatsFilterTenantIds } from 'pages/StatisticsPage';
import { useDispatch } from 'react-redux';
import { setFilterTenantIds } from 'services/statistics/slice';
import styles from './styles';

const useStyles = makeStyles(styles);
type TenantsFilterProps = { list: TenantItem[] };

const TenantsFilter: FC<TenantsFilterProps> = ({ list }) => {
  const styles = useStyles();
  const { t } = useTranslation(['StatisticsPage']);
  const dispatch = useDispatch();
  const [anchorGear, setAnchorGear] = useState<null | HTMLElement>(null);
  const [tenantsListOpened, setTenantsListOpened] = useState<boolean>(false);
  const [selectedTenantIds, setSelectedTenantIds] = useState<Dict<boolean>>({});
  const [initialTenantSelections, setInitialTenantSelections] = useState<Dict<boolean>>({});
  const [searchTerm, setSearchTerm] = useState('');
  const [isSelectionChanged, setIsSelectionChanged] = useState(false);

  const tenantIds = useMemo(
    () => Object.keys(selectedTenantIds).filter((tenant) => selectedTenantIds[tenant]),
    [selectedTenantIds],
  );

  const filteredTenants = useMemo(
    () => list.filter((tenant: TenantItem) => tenant?.name?.toLowerCase().includes(searchTerm.toLowerCase())),

    [list, searchTerm],
  );

  const handleTenantsListOpened = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setTenantsListOpened(true);
    setAnchorGear(event.currentTarget);
  }, []);

  const handleTenantsListClosed = useCallback(() => {
    setTenantsListOpened(false);
    setAnchorGear(null);
    setSearchTerm('');
    setSelectedTenantIds(initialTenantSelections);
  }, [initialTenantSelections]);

  const handleSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  }, []);

  const handleCheckboxChange = useCallback((tenantId: string, isChecked: boolean) => {
    setSelectedTenantIds((prev) => ({ ...prev, [tenantId]: isChecked }));
  }, []);

  const handleSelectAllChange = useCallback(
    (isChecked: boolean) => {
      const newSelections = Object.fromEntries(
        filteredTenants.map((tenant: TenantItem) => [tenant.tenantId, isChecked]),
      );
      setSelectedTenantIds(newSelections);
    },
    [filteredTenants],
  );

  const allSelected = useMemo(
    () => filteredTenants.every((tenant: TenantItem) => selectedTenantIds[tenant.tenantId as string]),
    [filteredTenants, selectedTenantIds],
  );

  const submitTenantFilter = () => {
    setTenantsListOpened(false);
    setAnchorGear(null);
    setSearchTerm('');
    setInitialTenantSelections(selectedTenantIds);
    if (tenantIds.length) {
      save(StatsFilterTenantIds, tenantIds, true);
    } else {
      remove(StatsFilterTenantIds, true);
    }
    dispatch(setFilterTenantIds(tenantIds));
  };

  useEffect(() => {
    const tenantIdsFromStorage = load(StatsFilterTenantIds, true);
    if (tenantIdsFromStorage && Array.isArray(tenantIdsFromStorage)) {
      const tenantSelectionFlags = Object.fromEntries(
        tenantIdsFromStorage.map((tenantId) => [tenantId, true]),
      );
      setSelectedTenantIds(tenantSelectionFlags);
      setInitialTenantSelections(tenantSelectionFlags);
      dispatch(setFilterTenantIds(tenantIdsFromStorage));
    }
  }, [dispatch]);

  useEffect(() => {
    const allKeys = new Set([
      ...Object.keys(selectedTenantIds),
      ...Object.keys(initialTenantSelections),
    ]);
    const isChanged = Array.from(allKeys).some(
      (key) => selectedTenantIds[key] !== (initialTenantSelections[key] || false),
    );
    setIsSelectionChanged(isChanged);
  }, [initialTenantSelections, selectedTenantIds]);

  return (
    <>
      <Tooltip title={t('Filter by tenant') as string}>
        <IconButton onClick={handleTenantsListOpened} disableRipple>
          <Badge color="primary" variant={tenantIds.length ? 'dot' : 'standard'}>
            <FilterIcon />
          </Badge>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorGear}
        onClose={handleTenantsListClosed}
        open={tenantsListOpened}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        classes={{ paper: styles.menu }}
      >
        <div className={styles.searchContainer}>
          {t('Tenant')}
          <input
            className={styles.searchInput}
            type="text"
            placeholder={t('Tenant search placeholder')}
            value={searchTerm}
            onChange={handleSearchChange}
            onKeyDown={(e) => e.stopPropagation()}
          />
        </div>
        {filteredTenants.length > 1 ? (
          <div className={styles.scrollContainer}>
            <div className={styles.menuItemContainer}>
              <KLCheckBox
                color="primary"
                checked={allSelected}
                onChange={(e) => handleSelectAllChange(e.target.checked)}
              />
              <MenuItem>{t('Select all')}</MenuItem>
            </div>
            {filteredTenants.map((tenant: TenantItem) => (
              <div className={styles.menuItemContainer} key={tenant.tenantId}>
                <KLCheckBox
                  color="primary"
                  value={tenant.tenantId}
                  checked={!!selectedTenantIds[tenant.tenantId]}
                  onChange={(e) => handleCheckboxChange(tenant.tenantId, e.target.checked)}
                />
                <MenuItem>{tenant.name}</MenuItem>
              </div>
            ))}
          </div>
        ) : (
          <div className={styles.noOptionsContainer}>{t('No options')}</div>
        )}
        <div className={styles.tenantFilterFooter}>
          <KLButton
            size="medium"
            color="primary"
            variant="contained"
            disabled={!isSelectionChanged}
            onClick={submitTenantFilter}
          >
            {t('Save')}
          </KLButton>
          <KLButton color="primary" onClick={handleTenantsListClosed}>
            {t('Cancel')}
          </KLButton>
        </div>
      </Menu>
    </>
  );
};

export default TenantsFilter;
