import { postRequest } from 'utils/http';
import {
  IncidentDetails,
  IncidentListSagaPayload,
  IncidentMarkAsReadPayload,
  IncidentCreatePayload,
  IncidentResolution,
  IncidentClosePayload,
  IncidentAutoResponse,
  IncidentAutoResponsePayload,
  IncidentIoc,
} from './types';

const url = 'incidents';

export function postIncidentList(payload: IncidentListSagaPayload) {
  return postRequest<IncidentDetails[]>(`${url}/list`, {
    paging: payload.paging,
    filter: payload.filter,
    searchPhrase: payload.searchPhrase,
  });
}

export function postIncidentCount(payload: IncidentListSagaPayload) {
  return postRequest<number>(`${url}/count`, {
    filter: payload.filter,
    searchPhrase: payload.searchPhrase,
  });
}

export function postIncidentDetails(id: string) {
  return postRequest<IncidentDetails>(`${url}/details`, {
    incidentId: id,
  });
}

export function postIncidentIocs(id: string) {
  return postRequest<IncidentIoc[]>(`${url}/iocs`, {
    incidentsIds: [id],
  });
}

export function postMarkAsRead(payload: IncidentMarkAsReadPayload) {
  return postRequest<never>(`${url}/mark-as-read`, {
    ...payload,
  });
}

export function postIncidentNewCount() {
  return postRequest<number>(`${url}/new-count`);
}

export function postIncidentCreate(payload: IncidentCreatePayload) {
  return postRequest<IncidentDetails>(`${url}/create`, {
    ...payload,
  });
}

export function getIncidentAutoResponse() {
  return postRequest<IncidentAutoResponse>(`${url}/get-auto-response`);
}

export function setIncidentAutoResponse({
  autoResponse,
  tenantlyMode,
  tenantsAutoResponse,
}: IncidentAutoResponsePayload) {
  return postRequest<never>(`${url}/set-auto-response`, {
    autoResponse,
    tenantlyMode,
    tenantsAutoResponse,
  });
}

export function closeIncident(payload: IncidentClosePayload) {
  const { resolution, comment, incidentId } = payload;
  return postRequest<never>(`${url}/close`, {
    resolutionStatus: resolution === IncidentResolution.FalsePositive ? 0 : 1,
    summary: comment,
    incidentId,
  });
}
