import { useSelector } from 'store/hook';
import { useDispatch } from 'react-redux';
import React, { ChangeEvent, FormEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePermissions } from 'components/Permissions/hooks';
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  makeStyles,
  Switch,
} from '@material-ui/core';
import { ExternalLinkIcon } from 'assets/icons/ExternalLinkIcon/icon';
import { KLCheckBox } from 'components/KLCheckBox';
import { KLInput } from 'components/KLInput';
import { validateEmail } from 'utils/validation';
import { KLSelect } from 'components/KLSelect';
import { getReportSettingsRequest, setReportSettingsRequest } from 'services/clientInformer/slice';
import { addNotification } from 'services/system/slice';
import { Redirect } from 'react-router-dom';
import { MAIN_PAGE } from 'global/routes';
import { KLButton } from 'components/KLButton';
import { ReportSettingsRecord } from 'services/clientInformer/types';
import styles from './styles';
import { extractTimeFromCron, extractWeekdayFromCron, WEEKDAYS } from './utils';

const useStyles = makeStyles(styles);

interface Validators {
  [key: string]: {
    valid: boolean;
    touched: boolean;
  };
}

type ReportSettingsFormProps = {
  onClose: () => void;
  schedule?: Partial<ReportSettingsRecord['clientReportSchedule']>;
};

const ROOT_TENANT_ID = '-';

export function ReportSettingsForm(props: ReportSettingsFormProps) {
  const { onClose, schedule } = props;
  const isEditing = Object.keys(schedule || {}).length > 0;
  const { isLoading } = useSelector((state) => state.clientInformer);
  const { clientId } = useSelector((state) => state.auth);
  const { isActiveOrganization } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const reportSettingsForm = useRef<HTMLFormElement>(null);
  const classes = useStyles();
  const { t } = useTranslation(['SettingsPage']);

  const [editReportingSettingsPerm] = usePermissions(['edit_organization_auto_reporting_settings']);

  const presetPeriods = [
    { value: '', label: t('ReportSettings.Select'), dayId: '-1' },
    { value: 'everyday', label: t('ReportSettings.Everyday'), dayId: '*' },
    { value: 'monday', label: t('ReportSettings.Monday'), dayId: '1' },
    { value: 'tuesday', label: t('ReportSettings.Tuesday'), dayId: '2' },
    { value: 'wednesday', label: t('ReportSettings.Wednesday'), dayId: '3' },
    { value: 'thursday', label: t('ReportSettings.Thursday'), dayId: '4' },
    { value: 'friday', label: t('ReportSettings.Friday'), dayId: '5' },
    { value: 'saturday', label: t('ReportSettings.Saturday'), dayId: '6' },
    { value: 'sunday', label: t('ReportSettings.Sunday'), dayId: '0' },
  ];

  const initialValidators: Validators = {
    risksReadAndConfirmed: { valid: true, touched: false },
    reportName: { valid: isEditing, touched: false },
    tenantId: { valid: true, touched: false },
    mailList: { valid: isEditing, touched: false },
    period: { valid: isEditing, touched: false },
    time: { valid: isEditing, touched: false },
  };

  const tenants = useSelector(({ tenants }) => tenants.list);
  const tenantOptions = useMemo(() => {
    const options = [{ label: t('ReportSettings.Tenant all'), value: '' }];
    for (const tenant of tenants) {
      if (tenant.tenantId === ROOT_TENANT_ID) {
        if (clientId && tenant.name) {
          options.push({ label: tenant.name, value: clientId });
        }
      } else if (tenant.tenantId && tenant.name) {
        options.push({
          label: tenant.name,
          value: tenant.tenantId,
        });
      }
    }
    return options;
  }, [clientId, t, tenants]);
  const [subscriptionEnabled, setSubscriptionEnabled] = useState(schedule?.enabled || false);
  const [riskInformationReadAndAccepted, setRiskInformationReadAndAccepted] = useState(
    schedule?.enabled === true,
  );
  const [reportName, setReportName] = useState(schedule?.description || '');
  const [tenantId, setTenantId] = useState<string>(schedule?.tenantId || '');
  const [mailList, setMailList] = useState(schedule?.emails?.join(', ') || '');
  const [periodValue, setPeriodValue] = useState(
    schedule?.schedule
      ? WEEKDAYS[extractWeekdayFromCron(schedule.schedule) as keyof typeof WEEKDAYS]
      : '',
  );
  const [timeValue, setTimeValue] = useState(
    schedule?.schedule ? extractTimeFromCron(schedule.schedule) : '',
  );

  const [validators, setValidators] = useState(initialValidators);
  const [isValidForm, setIsValidForm] = useState(false);

  useEffect(() => {
    dispatch(getReportSettingsRequest());
  }, [dispatch]);

  const wasChanges = useMemo(() => {
    const enabled = schedule?.enabled || false;
    const description = schedule?.description || '';
    const tenant = schedule?.tenantId || '';
    const cron = schedule?.schedule || '';
    const emails = schedule?.emails || [];
    const receivedDayId = extractWeekdayFromCron(cron);

    return (
      enabled !== subscriptionEnabled
      || description !== reportName
      || tenant !== tenantId
      || (presetPeriods.find((period) => period.dayId === receivedDayId)?.value || '')
        !== periodValue
      || emails.join(',') !== mailList
      || (cron.length ? extractTimeFromCron(cron) : '') !== timeValue
    );
  }, [subscriptionEnabled, reportName, periodValue, mailList, timeValue, presetPeriods, tenantId]);

  const timeInputRef = useRef<HTMLInputElement | null>(null);

  const checkValidity = () => {
    let res = true;
    for (const i in validators) {
      if (!validators[i].valid) {
        res = false;
        break;
      }
    }
    if (isValidForm !== res) setIsValidForm(res);
  };

  useEffect(checkValidity, [validators, isValidForm]);

  const touchedHandler = (validatorName: string) => {
    const newValidator = {
      ...validators,
      [validatorName]: { ...validators[validatorName], touched: true },
    };
    setValidators(newValidator);
  };

  const submitHandler = (event: FormEvent) => {
    event.preventDefault();
    const [hours, minutes] = timeValue.split(':');
    const { dayId } = presetPeriods.find((period) => periodValue === period.value)!;
    const cron = `${minutes} ${hours} * * ${dayId}`;
    const emails = mailList.split(',').map((address) => address.trim());
    if (isValidForm) {
      dispatch(
        setReportSettingsRequest({
          client: clientId,
          settings: {
            emails,
            type: schedule?.type
              ? schedule?.type
              : `report_schedule_portal_${Date.now().toString()}`,
            schedule: cron,
            format: 'pdf',
            source: 'thehive',
            description: reportName,
            enabled: subscriptionEnabled,
            tenantId,
          },
          message: t('ReportSettings.Report settings were saved successfully'),
          resolve: () => {
            onClose();
            dispatch(getReportSettingsRequest());
          },
        }),
      );
    } else {
      dispatch(
        addNotification({
          message: t('ReportSettings.Please fill in the form'),
          options: { variant: 'error' },
        }),
      );
    }
  };

  const resetRiskConfirmationIfNecessary = () => {
    if (isEditing) {
      setRiskInformationReadAndAccepted(false);
    }
    if (subscriptionEnabled) {
      setValidators((validators) => ({
        ...validators,
        risksReadAndConfirmed: { valid: riskInformationReadAndAccepted, touched: true },
      }));
    } else {
      setValidators((validators) => ({
        ...validators,
        risksReadAndConfirmed: { valid: true, touched: false },
      }));
    }
  };

  useEffect(() => {
    if (wasChanges) {
      resetRiskConfirmationIfNecessary();
    }
  }, [subscriptionEnabled, wasChanges]);

  const subscriptionEnabledChangeHandler = () => {
    const newValue = !subscriptionEnabled;
    setSubscriptionEnabled(newValue);
  };

  const risksReadAndConfirmedChangeHandler = () => {
    const newValue = !riskInformationReadAndAccepted;
    setRiskInformationReadAndAccepted(newValue);
    if (subscriptionEnabled) {
      setValidators((validators) => ({
        ...validators,
        risksReadAndConfirmed: { valid: newValue, touched: true },
      }));
    } else {
      setValidators((validators) => ({
        ...validators,
        risksReadAndConfirmed: { valid: true, touched: true },
      }));
    }
  };

  const reportNameChangeHandler = (event: ChangeEvent) => {
    const reportName = (event.target as HTMLInputElement).value;
    setReportName(reportName);
    if (reportName) {
      setValidators({
        ...validators,
        reportName: { ...validators.reportName, valid: true, touched: true },
      });
    } else {
      setValidators({
        ...validators,
        reportName: { ...validators.reportName, valid: false, touched: true },
      });
    }
    resetRiskConfirmationIfNecessary();
  };

  const tenantIdChangeHandler = (event: any) => {
    const tenantId = (event.target as HTMLInputElement).value;
    setTenantId(tenantId);
    setValidators({
      ...validators,
      tenantId: { ...validators.tenantId, valid: true, touched: true },
    });
    resetRiskConfirmationIfNecessary();
  };

  const periodChangeHandler = (event: any) => {
    const period = (event.target as HTMLInputElement).value;
    setPeriodValue(period);
    if (period) {
      setValidators({
        ...validators,
        period: { ...validators.period, valid: true, touched: true },
      });
    } else {
      setValidators({
        ...validators,
        period: { ...validators.period, valid: false, touched: true },
      });
    }
    resetRiskConfirmationIfNecessary();
  };

  const mailListChangeHandler = (event: ChangeEvent) => {
    const mailList = (event.target as HTMLTextAreaElement).value;
    const mailArray = mailList.split(',');
    const allEmailsValid = mailArray.every((address) => validateEmail(address));
    setMailList(mailList);
    if (allEmailsValid) {
      setValidators({
        ...validators,
        mailList: { ...validators.mailList, valid: true, touched: true },
      });
    } else {
      setValidators({
        ...validators,
        mailList: { ...validators.mailList, valid: false, touched: true },
      });
    }
    resetRiskConfirmationIfNecessary();
  };

  const timeChangeHandler = (event: ChangeEvent) => {
    const time = (event.target as HTMLInputElement).value;
    const timePattern = new RegExp(/^[0-2]?[0-9]?:[0-5]?[0-9]?$/);
    const isValidTimeFormat = timePattern.test(time);

    let isValidTimeValue = false;
    let isValidHours = false;
    let isValidMinutes = false;
    setTimeValue(time);

    if (isValidTimeFormat) {
      const [hours, minutes] = time.split(':');
      isValidHours = parseInt(hours, 10) >= 0 && parseInt(hours, 10) < 24;
      isValidMinutes = parseInt(minutes, 10) >= 0 && parseInt(minutes, 10) < 60;
      isValidTimeValue = isValidHours && isValidMinutes;
    }

    if (isValidTimeValue) {
      setValidators({ ...validators, time: { ...validators.time, valid: true, touched: true } });
    } else {
      setValidators({ ...validators, time: { ...validators.time, valid: false, touched: true } });
    }
    resetRiskConfirmationIfNecessary();
  };

  if (!isActiveOrganization) {
    return <Redirect to={MAIN_PAGE} />;
  }

  return (
    <form name="ReportSettingsForm" className={classes.root} ref={reportSettingsForm} noValidate>
      <Grid container direction="column" wrap="nowrap" spacing={2} className={classes.grid}>
        <Grid item sm={12} className={classes.item}>
          {t('ReportSettings.Schedule limits')}
        </Grid>
        <Grid item sm={12} className={classes.item}>
          <FormControlLabel
            control={(
              <Switch
                checked={subscriptionEnabled}
                onChange={subscriptionEnabledChangeHandler}
                name="subscription"
                disabled={!editReportingSettingsPerm}
              />
            )}
            label={t(
              subscriptionEnabled
                ? 'ReportSettings.SendingEnabled'
                : 'ReportSettings.SendingDisabled',
            )}
          />
        </Grid>
        {((!isEditing && subscriptionEnabled)
          || (wasChanges && isEditing && subscriptionEnabled)) && (
          <>
            <Grid item sm={12} className={classes.item}>
              {t('ReportSettings.Risks of email reports')}
              <a
                className={classes.link}
                href={t('ReportSettings.Risks of email reports help link')}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('ReportSettings.Risks of email reports help link name')} <ExternalLinkIcon />
              </a>
              .
            </Grid>
            <Grid item sm={12} className={classes.item}>
              <FormControlLabel
                control={(
                  <KLCheckBox
                    color="primary"
                    name="riskInformationReadAndAccepted"
                    checked={riskInformationReadAndAccepted}
                    onChange={risksReadAndConfirmedChangeHandler}
                  />
                )}
                label={t('ReportSettings.Risks of email reports understood and confirmed')}
              />
            </Grid>
          </>
        )}
        <Grid item sm={12} className={classes.item}>
          <FormControl
            fullWidth
            error={!validators.reportName.valid && validators.reportName.touched}
          >
            <FormLabel className={classes.label} htmlFor="reportName">
              {t('ReportSettings.Name')}
            </FormLabel>
            <KLInput
              id="reportName"
              type="text"
              name="reportName"
              placeholder={t('ReportSettings.My report')}
              noMarginTop
              value={reportName}
              onChange={reportNameChangeHandler}
              onBlur={() => touchedHandler('reportName')}
              disabled={!editReportingSettingsPerm}
            />
            <FormHelperText className={classes.errorMessage}>
              {t('ReportSettings.Please fill in the report name')}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item sm={12} className={classes.item}>
          <FormControl fullWidth error={!validators.tenantId.valid && validators.tenantId.touched}>
            <FormLabel className={classes.label} htmlFor="tenantId">
              {t('ReportSettings.Tenant name')}
            </FormLabel>
            <KLSelect
              items={tenantOptions}
              value={tenantId}
              onChange={tenantIdChangeHandler}
              onBlur={() => touchedHandler('tenantId')}
              displayEmpty
              disabled={!editReportingSettingsPerm}
            />
          </FormControl>
        </Grid>
        <Grid item sm={12} className={classes.item}>
          <FormGroup>
            <FormControl error={!validators.mailList.valid && validators.mailList.touched}>
              <FormLabel className={classes.label}>{t('ReportSettings.Email addresses')}</FormLabel>
              <textarea
                className={classes.mailList}
                name="mailList"
                value={mailList}
                placeholder={t('ReportSettings.emailsPlaceholder')}
                onChange={mailListChangeHandler}
                onBlur={() => touchedHandler('mailList')}
                disabled={!editReportingSettingsPerm}
              />
              <FormHelperText className={classes.errorMessage}>
                {t('ReportSettings.Please enter comma-separated list of valid email addresses')}
              </FormHelperText>
            </FormControl>
          </FormGroup>
        </Grid>
        <Grid item sm={12} className={classes.item}>
          <FormControl fullWidth error={!validators.period.valid && validators.period.touched}>
            <FormLabel className={classes.label}>{t('ReportSettings.Day')}</FormLabel>
            <KLSelect
              items={presetPeriods}
              value={periodValue}
              onChange={periodChangeHandler}
              onBlur={() => touchedHandler('period')}
              displayEmpty
              disabled={!editReportingSettingsPerm}
            />
            <FormHelperText className={classes.errorMessage}>
              {t('ReportSettings.Please select a value')}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item sm={12} className={classes.item}>
          <FormControl fullWidth error={!validators.time.valid && validators.time.touched}>
            <FormLabel className={classes.label} htmlFor="timeField">
              {t('ReportSettings.Time')}
            </FormLabel>
            <KLInput
              ref={timeInputRef}
              id="timeField"
              type="text"
              name="time"
              placeholder="00:00"
              noMarginTop
              value={timeValue}
              onChange={timeChangeHandler}
              onBlur={() => touchedHandler('time')}
              disabled={!editReportingSettingsPerm}
            />
            <FormHelperText className={classes.errorMessage}>
              {t('ReportSettings.The time should be specified within')}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item className={classes.buttons}>
          <KLButton
            type="submit"
            color="primary"
            variant="contained"
            disabled={!isValidForm || (isValidForm && !wasChanges)}
            isLoading={isLoading}
            onClick={submitHandler}
          >
            {t('Save')}
          </KLButton>
          <KLButton color="primary" variant="outlined" onClick={onClose} disabled={isLoading}>
            {t('Cancel')}
          </KLButton>
        </Grid>
      </Grid>
    </form>
  );
}
