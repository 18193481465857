export const indicatorTypeColors: Record<string, string> = {
  currentHostsCount: '#51AEE2',
  accessibleHostsCount: '#EC64A1',
  totalHostsCount: '#F3A536',
};

export const FIELD_WITH_ALERT_ICON = 'totalHostsCount';
export enum HOST_COUNTS_NAMES {
  ACCESSIBLE_HOSTS_COUNT = 'accessibleHostsCount',
  LICENSE_LIMIT_HOSTS_COUNT = 'licenseLimitHostsCount',
  TOTAL_HOSTS_COUNT = 'totalHostsCount',
  CURRENT_HOSTS_COUNT = 'currentHostsCount',
}
export const ALERT_COLOR = '#EB545B';
