import { takeLatest, put, call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { addNotification } from 'services/system/slice';
import { fixTimezoneOffset } from 'utils/date';
import { TENANT_EXISTS_ALREADY } from 'global/errors';
import { getT } from 'utils/i18n';
import {
  listRequest,
  deleteRequest,
  validateRequest,
  createRequest,
  configRequest,
  suggestionRequest,
  listStart,
  listSuccess,
  detailsStart,
  detailsSuccess,
  error,
  startSuggestion,
  setSuggestion,
  hasAccessToRootRequest,
  setHasAccessToRoot,
} from './slice';

import {
  getTenantsList,
  deleteTenant,
  createTenant,
  updateTenant,
  validateTenant,
  downloadTenantConfig,
  postTenantSuggestion,
  postHasAccessToRoot,
} from './api';

import { TenantItem } from './types';

function* fetchUserList() {
  yield put(listStart());
  try {
    const tenants = yield call(getTenantsList, true);
    yield put(listSuccess(tenants));
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

function* sendDeleteTenant(action: PayloadAction<string>) {
  try {
    yield call(deleteTenant, action.payload);
    yield call(fetchUserList);
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

function* prepareForTenant(action: PayloadAction<TenantItem>) {
  yield put(detailsStart());
  try {
    const { payload } = action;
    const rules = yield call(validateTenant, payload?.tenantId);
    yield put(detailsSuccess({ ...payload, rules }));
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

function* createOrUpdateTenant(action: PayloadAction<TenantItem>) {
  const { payload } = action;
  yield put(detailsStart());
  try {
    if (payload.tenantId) {
      yield call(updateTenant, payload);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { licenseEndDate, licenseStartDate, ...createPayload } = payload;
      yield call(createTenant, {
        ...createPayload,
        licenseEndDate: licenseEndDate ? fixTimezoneOffset(licenseEndDate, false) : licenseEndDate,
      });
    }
    yield put(detailsSuccess(null));
    yield call(fetchUserList);
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
    const trMessage = message === TENANT_EXISTS_ALREADY
      ? getT('errors:Tenant exists already', { name: payload.tenantName })
      : message;
    yield put(addNotification({ message: trMessage, options: { variant: 'error' } }));
  }
}

function* downloadConfigSaga(
  action: PayloadAction<{ tenantId: string; blobId: string; purpose: number }>,
) {
  const { tenantId, blobId, purpose } = action.payload;
  try {
    yield call(downloadTenantConfig, tenantId, blobId, purpose);
  } catch (err) {
    const message = err.message || err;
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

function* fetchTenantSuggestion(action: PayloadAction<string>) {
  yield put(startSuggestion());

  try {
    const suggestion = yield call(postTenantSuggestion, action.payload);
    yield put(setSuggestion(suggestion));
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

function* fetchHasAccessToRoot() {
  try {
    const hasAccessToRoot = yield call(postHasAccessToRoot);
    yield put(setHasAccessToRoot(hasAccessToRoot));
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

export const tenantsSaga = [
  takeLatest(listRequest.type, fetchUserList),
  takeLatest(deleteRequest.type, sendDeleteTenant),
  takeLatest(validateRequest.type, prepareForTenant),
  takeLatest(createRequest.type, createOrUpdateTenant),
  takeLatest(configRequest.type, downloadConfigSaga),
  takeLatest(suggestionRequest.type, fetchTenantSuggestion),
  takeLatest(hasAccessToRootRequest.type, fetchHasAccessToRoot),
];
