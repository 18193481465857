import React, { useEffect, FC, useState } from 'react';
import useStyles from './styles';
import { IndicatorProps, AssetsDisplayData } from './types';

const Indicator: FC<IndicatorProps> = ({ data, maxValue, activeItem }) => {
  const classes = useStyles();

  const [displayData, setDisplayData] = useState<AssetsDisplayData[]>([]);
  useEffect(() => {
    let newDisplayData = [];
    if (activeItem) {
      newDisplayData = data.filter((item) => item.name === activeItem);
    } else {
      newDisplayData = [...data];
    }

    newDisplayData = newDisplayData.map((element) => ({
      ...element,
      value: (element.value / maxValue) * 100,
    }));
    newDisplayData.sort((a, b) => b.value - a.value);
    setDisplayData(newDisplayData);
  }, [activeItem, data, maxValue]);
  return (
    <div className={classes.indicator}>
      {displayData.map((element) => (
        <div
          className={classes.indicatorLine}
          style={{ width: `${element.value}%`, background: `${element.color}` }}
          key={element.name}
        />
      ))}
    </div>
  );
};

export default Indicator;
