import useStyles from 'pages/StatisticsPage/utils/useStyles';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'store/hook';
import { useTranslation } from 'react-i18next';
import { useHoverHooks } from 'pages/StatisticsPage/utils/hoverHooks';
import { usePermissions } from 'components/Permissions/hooks';
import { TenantItem } from 'services/tenants/types';
import Indicator from './Indicator';
import { indicatorTypeColors, HOST_COUNTS_NAMES } from './constants';
import { Legend } from './Legend';
import Footer from './Footer';
import { AssetsData, AssetsDisplayData } from './types';

const calculateMaxScale = (data: AssetsData[], licenseAssetsCount: number) => {
  let maxAssets = -Infinity;
  for (const item of data) {
    if (item.value > maxAssets) {
      maxAssets = item.value;
    }
  }
  return licenseAssetsCount > maxAssets ? licenseAssetsCount : maxAssets;
};

const injectColors = (data: AssetsData[]) => {
  if (!data) return [];
  const result = [];
  for (const obj of data) {
    const color: string | undefined = indicatorTypeColors[obj.name];
    if (color) {
      result.push({ color, value: obj.value, name: obj.name });
    }
  }
  return result;
};

const prepareDisplayData = (
  data: AssetsData[],
  tenantFilter: string[],
  accessibleTenantsList: TenantItem[],
) => {
  const dataMap = new Map();
  for (const item of data) {
    dataMap.set(item.name, item.value);
  }
  let displayData = [...data];
  displayData = displayData.filter((item) => {
    if (item.name === HOST_COUNTS_NAMES.CURRENT_HOSTS_COUNT) {
      if (!tenantFilter.length) {
        return false;
      }
      if (tenantFilter.length === accessibleTenantsList.length) {
        return false;
      }
    }
    if (item.name === HOST_COUNTS_NAMES.ACCESSIBLE_HOSTS_COUNT) {
      if (item.value === dataMap.get(HOST_COUNTS_NAMES.TOTAL_HOSTS_COUNT)) {
        return false;
      }
    }
    return true;
  });
  return injectColors(displayData);
};

const getLicenseLimitHostsCount = (data: AssetsData[]) => {
  const value = data?.find(
    (item) => item.name === HOST_COUNTS_NAMES.LICENSE_LIMIT_HOSTS_COUNT,
  )?.value;
  return value ?? 0;
};

const AssetsStats = () => {
  const classes = useStyles();
  const [activeItem, setActiveItem] = useState('');
  const { t } = useTranslation(['StatisticsPage']);
  const [isMdrAdmin] = usePermissions(['edit_organization_license']);
  const [displayData, setDisplayData] = useState<AssetsDisplayData[]>([]);
  const [maxHosts, setMaxHosts] = useState(0);
  const [licenseLimitHostsCount, setLicenseLimitHostsCount] = useState(0);

  const { list: accessibleTenantsList } = useSelector((state) => state.tenants);
  const {
    data: { licenseStats = {} },
    filterTenantIds,
  } = useSelector((state) => state.statistics);

  const { onMouseEnter, onMouseLeave } = useHoverHooks(
    (target) => {
      if (target) {
        const id = target.dataset?.id || target.getAttribute('data-id');
        setActiveItem(id || '');
      }
    },
    () => {
      setActiveItem('');
    },
  );

  useEffect(() => {
    const newDisplayData = prepareDisplayData(
      licenseStats.data ?? [],
      filterTenantIds,
      accessibleTenantsList,
    );
    const newLicenseLimitHostsCount = getLicenseLimitHostsCount(licenseStats.data);
    const newMaxHost = calculateMaxScale(newDisplayData, newLicenseLimitHostsCount);
    setDisplayData(newDisplayData);
    setMaxHosts(newMaxHost);
    setLicenseLimitHostsCount(newLicenseLimitHostsCount);
  }, [accessibleTenantsList, filterTenantIds, licenseStats.data]);

  return (
    <div className={classes.chartContainer}>
      <div className={classes.chartTile}>
        <h2 className={classes.subheader}>{t('Number of connected assets')}</h2>
        <Indicator data={displayData} maxValue={maxHosts} activeItem={activeItem} />
        <Legend
          data={displayData}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          activeItem={activeItem}
          maxAssetsCount={licenseLimitHostsCount}
        />
        <div className={classes.divider} />
        <Footer
          classes={classes}
          t={t}
          isMdrAdmin={isMdrAdmin}
          licenseAssetsCount={licenseLimitHostsCount}
        />
      </div>
    </div>
  );
};

export default AssetsStats;
