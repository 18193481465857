import { Theme, createStyles } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  container: {
    padding: theme.spacing(0, 2),
  },
  property: {
    marginTop: theme.spacing(2),
    width: '100%',
    wordBreak: 'break-word',
    overflowWrap: 'break-word',
  },
  label: {
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(14),
  },
  value: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontSize: theme.typography.pxToRem(14),
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    flexShrink: 0,
    zIndex: 1,
    paddingBottom: theme.spacing(4),
  },
});
