import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Tooltip, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useSelector } from 'store/hook';
import { usePermissions } from 'components/Permissions/hooks';
import { LinkButton } from 'components/LinkButton';
import { DateTime } from 'components/DateTime';
import { ACTIVATION_PAGE } from 'global/routes';
import {
  infoRequest,
} from 'services/activation/slice';
import { useDispatch } from 'react-redux';
import { Loading } from 'components/Loading';
import { SuccessCircleIcon } from 'assets/icons/SuccessCircleIcon';
import { ErrorCircleIcon } from 'assets/icons/ErrorCircleIcon';
import { InfoIcon } from 'assets/icons/InfoIcon';
import { getLocale } from 'utils/i18n';
import { getValueOrDefault } from 'utils/common';
import { DataLine } from './components/DataLine';
import styles from './styles';

const useStyles = makeStyles(styles);

const READ_ONLY_REGIONS = ['usa_canada'];
const READ_ONLY_REGIONS_AFTER = [2024, 9, 10];

function canRenewLicense(region: string) {
  if (!region) {
    return false;
  }
  const [year, month, date] = READ_ONLY_REGIONS_AFTER;
  const deadline = new Date(year, month - 1, date).setUTCHours(0, 0, 0, 0);
  if (READ_ONLY_REGIONS.includes(region) && deadline < Date.now()) {
    return false;
  }
  return true;
}

export const LicenseStatePage = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation(['LicenseStatePage', 'licenseTypes']);
  const classes = useStyles();
  const {
    licenseType,
    activationDate,
    expirationDate,
    assetsCount,
    daysLeftToUse,
    isLoading,
    isLicenseBlocked,
    solution,
    activatedRegion,
  } = useSelector(state => state.activation);
  const [editLicensePerm] = usePermissions(['edit_organization_license']);

  useEffect(() => {
    dispatch(infoRequest());
  }, [dispatch]);

  const canRenewLicenseValue = useMemo(
    () => canRenewLicense(activatedRegion), 
    [activatedRegion, new Date().getUTCHours()],
  );
  
  if (isLoading) {
    return <Loading />;
  }

  const regionToTelemetry = {
    europe: t('neu'),
    // eslint-disable-next-line @typescript-eslint/camelcase
    usa_canada: t('neu'),
    canada: t('neu'),
    latam: t('br'),
    russia: t('rus'),
    ksa: t('ksa'),
    rest: t('rus'),
  };

  return (
    <Box className={classes.container}>
      {(!daysLeftToUse && daysLeftToUse !== null || isLicenseBlocked)
        ? <ErrorCircleIcon className={classes.icon} />
        : <SuccessCircleIcon className={classes.icon} />}

      <Typography className={classes.title} variant="h1" component="h1">
        {(!daysLeftToUse && daysLeftToUse !== null || isLicenseBlocked)
          ? t('Application is in read-only mode')
          : t('Application activated successfully')}
      </Typography>
      <DataLine title={t('License type')} value={licenseType ? t(`licenseTypes:${licenseType}`) : null} />
      <DataLine title={t('Solution')} value={solution ? t(solution) : null} />
      <DataLine title={t('telemetry')} value={(
        <Box>{
            getValueOrDefault(regionToTelemetry, activatedRegion, t('rus'))
          }
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title={(
              <>
                {t('regionTooltip')}&nbsp;
                <a href={t('regionTooltipLink', { locale: getLocale() })} target="_blank" rel="noopener noreferrer">
                  {t('regionTooltipLinkText', { locale: getLocale() })}
                </a>.
              </>
            )} 
            arrow 
            interactive 
            placement="right" 
            leaveDelay={1000}
          >
            <span>
              <InfoIcon className={classes.infoIcon} fill={theme.palette.info.main} />
            </span>
          </Tooltip>
        </Box>
        )}
      />
      <DataLine title={t('Activation date')} value={activationDate ? <DateTime timestamp={activationDate} /> : null} />
      <DataLine title={t('Expiration date')} value={expirationDate ? <DateTime timestamp={expirationDate} /> : t('Not limited')} />
      <DataLine title={t('Days left to use')} value={(daysLeftToUse !== null) ? daysLeftToUse : t('Not limited') } />
      <DataLine title={t('Number of assets')} value={assetsCount} />

      {editLicensePerm && canRenewLicenseValue && (
        <LinkButton
          className={classes.btn}
          variant="outlined"
          color="primary"
          to={`${ACTIVATION_PAGE}/new`}
        >
          {t('Enter a new activation code')}
        </LinkButton>
      )}
    </Box>
  );
};
