import React, { useState, useEffect, FC } from 'react';
import { Link } from 'react-router-dom';
import { FooterProps } from './types';

const Footer: FC<FooterProps> = ({ classes, isMdrAdmin, licenseAssetsCount, t }) => {
  const [textParts, setTextParts] = useState({ maximumAssetsFor: '', license: '' });

  useEffect(() => {
    const newTextParts = { maximumAssetsFor: '', license: '' };
    const translation = t('Maximum assets for license');
    const words = translation.split(' ');
    newTextParts.license = words.pop() ?? '';
    newTextParts.maximumAssetsFor = words.join(' ');
    setTextParts(newTextParts);
  }, [t]);

  return (
    <div className={classes.listItem}>
      <div className={classes.names}>
        <span>{`${textParts.maximumAssetsFor} `}</span>
        {isMdrAdmin ? (
          <Link to="/license">{textParts.license}</Link>
        ) : (
          <span>{textParts.license}</span>
        )}
      </div>
      <div className={classes.values}>{licenseAssetsCount || 0}</div>
    </div>
  );
};

export default Footer;
