import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import AlertIcon from 'assets/icons/AlertIcon';
import { Tooltip } from '@material-ui/core';
import nameMatching from '../../utils/nameMatching';
import useStyles from './styles';
import { indicatorTypeColors, FIELD_WITH_ALERT_ICON, ALERT_COLOR } from './constants';
import { LegendProps } from './types';

export const Legend: FC<LegendProps> = ({
  onMouseEnter,
  onMouseLeave,
  data,
  activeItem,
  maxAssetsCount,
}) => {
  const { t } = useTranslation(['StatisticsPage']);
  const classes = useStyles();

  return (
    <>
      {data.map((item: any) => {
        const isNotActive = activeItem && activeItem !== item.name;
        const textColor = isNotActive ? '#B8B5B5' : 'black';
        const dotColor = isNotActive ? '#B8B5B5' : indicatorTypeColors[item.name];
        const limitExcided = item.name === FIELD_WITH_ALERT_ICON ? maxAssetsCount - item.value : 0;
        return (
          <div
            className={classes.listItem}
            key={item.name}
            data-id={item.name}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            style={{ color: textColor }}
          >
            <div
              data-id={item.name}
              className={classes.marker}
              style={{ backgroundColor: dotColor }}
            />
            <div data-id={item.name} className={classes.names}>
              {t(nameMatching[item.name] || item.name)}
            </div>
            <div data-id={item.name} className={classes.values}>
              {limitExcided < 0 ? (
                <Tooltip
                  title={`${t('The number of connected assets has been exceeded by')} ${Math.abs(limitExcided)}`}
                  arrow
                  placement="left"
                >
                  <div>
                    <AlertIcon color={ALERT_COLOR} />
                  </div>
                </Tooltip>
              ) : null}
              <span style={{ color: limitExcided < 0 ? ALERT_COLOR : 'black' }}>{item.value}</span>
            </div>
          </div>
        );
      })}
    </>
  );
};
