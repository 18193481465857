import React, { FC } from 'react';

type AlertIconProps = {
  color: string;
};

const AlertIcon: FC<AlertIconProps> = ({ color }) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg ">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M9.2229 1.51785C8.27807 -0.23121 5.76857 -0.231209 4.82374 1.51785L0.492392 9.53593C-0.407399 11.2016 0.798805 13.2241 2.69197 13.2241H11.3547C13.2478 13.2241 14.454 11.2016 13.5542 9.53593L9.2229 1.51785ZM6.99982 3.50098C7.41403 3.50101 7.74979 3.83683 7.74976 4.25104L7.74946 7.74317C7.74942 8.15738 7.4136 8.49314 6.99939 8.4931C6.58518 8.49307 6.24942 8.15725 6.24946 7.74304L6.24976 4.25091C6.24979 3.8367 6.58561 3.50094 6.99982 3.50098ZM7.8499 10.3554C7.8499 10.8248 7.46934 11.2054 6.9999 11.2054C6.53046 11.2054 6.1499 10.8248 6.1499 10.3554C6.1499 9.88593 6.53046 9.50537 6.9999 9.50537C7.46934 9.50537 7.8499 9.88593 7.8499 10.3554Z"
      fill={color}
    />
  </svg>
);

export default AlertIcon;
