import useStyles from 'pages/StatisticsPage/utils/useStyles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'store/hook';
import LineChart from '../LineChart';

const IncindetsStats = () => {
  const classes = useStyles();
  const { t } = useTranslation(['StatisticsPage']);
  const {
    data: { stats = {} },
  } = useSelector((state) => state.statistics);

  return (
    <div className={classes.chartContainer}>
      <div className={classes.chartTile}>
        <h2 className={classes.subheader}>{t('Number of incidents')}</h2>
        <div>
          <LineChart data={stats.data} options={stats.options} />
        </div>
      </div>
    </div>
  );
};

export default IncindetsStats;
