import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StatisticsState, StatItem, TelemetryStatsRequest, StatsRequest } from './types';

const name = 'statistics';

export const statsRequest = createAction<StatsRequest>(`${name}/details`);
export const telemetryStatsRequest = createAction<TelemetryStatsRequest>(
  `${name}/telemetryDetails`,
);
export const checkRequest = createAction(`${name}/check`);

const limits = (data: any, assetsCount: number): { data: any } | undefined => {
  if (!data) {
    return undefined;
  }

  const valueName = 'currentHostsCount';
  const limitName = 'licenseLimitHostsCount';
  const totalAssetsName = 'totalHostsCount';

  const value = data[valueName];
  const limit = data[limitName];
  const total = data[totalAssetsName];

  return {
    data: [
      { name: totalAssetsName, value: total },
      { name: 'accessibleHostsCount', value: assetsCount },
      { name: valueName, value },
      { name: limitName, value: limit },
    ],
  };
};

const plainData = (data: any = {}) => ({
  data: Object.keys(data).map((key: string) => ({
    name: key,
    value: data[key],
  })),
  options: {},
});

const process = (obj: any, name: string, time: any, value: number) => {
  const x = { date: time.seconds * 1000, value };

  if (obj[name]) {
    obj[name].push(x);
  } else {
    obj[name] = [x];
  }
};

const processPeriods = (data: StatItem[] = []): any => {
  const sortedByTime: Record<string, any> = {};

  const _data = data.reduce((obj: any, item: StatItem) => {
    const { time, open, resolved, closed, onHold } = item;

    process(obj, 'open', time, open);
    process(obj, 'resolved', time, resolved);
    process(obj, 'closed', time, closed);
    process(obj, 'onHold', time, onHold);

    sortedByTime[`${time.seconds}000`] = [
      ['open', open],
      ['resolved', resolved],
      ['onHold', onHold],
      ['closed', closed],
    ];

    return obj;
  }, {});

  return {
    data: Object.keys(_data).map((k: string) => ({ name: k, values: _data[k] })),
    options: {
      sortingHelper: sortedByTime,
    },
  };
};

const initialState: StatisticsState = {
  telemetryStats: null,
  telemetryStatsDaysCount: null,
  telemetryStatsError: null,
  telemetryStatsLoading: false,
  data: {},
  isLoading: false,
  error: null,
  isChecking: false,
  isAvailable: null,
  filterTenantIds: [],
};

const statisticsSlice = createSlice({
  name,
  initialState,
  reducers: {
    start(state) {
      state.isLoading = true;
      state.error = null;
    },
    dataSuccess(state, action: PayloadAction<any>) {
      if (action.payload) {
        const {
          activeIncidentsStatsByPriority,
          statusesCount,
          licenseStats,
          responsesStatsByStatus,
          stats,
          assetsCount,
        } = action.payload;

        state.data = {
          activeIncidentsStatsByPriority: plainData(activeIncidentsStatsByPriority),
          assetsByStatus: statusesCount,
          licenseStats: limits(licenseStats, assetsCount),
          responsesStatsByStatus: plainData(responsesStatsByStatus),
          stats: processPeriods(stats),
        };
      }
      state.isLoading = false;
    },
    error(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    startCheck(state) {
      state.isChecking = true;
    },
    setAvailability(state, action: PayloadAction<boolean>) {
      state.isChecking = false;
      state.isAvailable = action.payload;
    },
    telemetryStatsStart(state, { payload }: PayloadAction<TelemetryStatsRequest>) {
      state.telemetryStatsLoading = true;
      state.telemetryStatsDaysCount = payload.dayRange;
    },
    telemetryStatsSuccess(state, { payload }: PayloadAction<StatisticsState['telemetryStats']>) {
      state.telemetryStatsLoading = false;
      state.telemetryStats = payload;
      state.telemetryStatsError = null;
    },
    telemetryStatsError(state, action: PayloadAction<string>) {
      state.telemetryStatsLoading = false;
      state.telemetryStatsError = action.payload;
    },
    setFilterTenantIds(state, { payload }: PayloadAction<string[]>) {
      state.filterTenantIds = payload;
    },
  },
});

export const {
  start,
  dataSuccess,
  error,
  startCheck,
  setAvailability,
  telemetryStatsError,
  telemetryStatsStart,
  telemetryStatsSuccess,
  setFilterTenantIds,
} = statisticsSlice.actions;

export default statisticsSlice.reducer;
