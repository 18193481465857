import { createStyles, Theme } from '@material-ui/core/styles';

export default (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2, 2, 2, 6),
    },
    row: {
      display: 'flex',
      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
      },
      justifyContent: 'stretch',
      alignItems: 'stretch',
      gap: `${theme.spacing(1)}px`,
      paddingBottom: `${theme.spacing(1)}px`,
    },
    cell: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
    },
    cellSm: {
      flexBasis: '40%',
      [theme.breakpoints.down('md')]: {
        flexBasis: '100%',
      },
    },
    cellLg: {
      flexBasis: '60%',
      [theme.breakpoints.down('md')]: {
        flexBasis: '100%',
      },
    },
    title: {
      marginRight: theme.spacing(6),
    },
    subheader: {
      fontSize: '20px',
      lineHeight: '29px',
      fontStyle: 'normal',
      fontWeight: 'bold',
    },
    settingButton: {
      whiteSpace: 'nowrap',
    },
    chartContainer: {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'stretch',
      justifyContent: 'stretch',
      gap: `${theme.spacing(1)}px`,
    },
    chartTile: {
      padding: theme.spacing(2),
      border: '1px solid #e4e4e4',
      borderRadius: theme.spacing(1),
      boxSizing: 'border-box',
      width: '100%',
    },
    divider: {
      height: '1px',
      background: '#EAEEF2',
      width: '100%',
      margin: '8px 0',
    },
    listItem: {
      display: 'flex',
      alignItems: 'baseline',
      marginBottom: '8px',
      fontWeight: 300,
      fontSize: '14px',
      maxWidth: '100%',
      '& a': {
        color: '#00A88E',
      },
    },
    names: {
      flexGrow: 1,
    },
    values: {
      minWidth: '25px',
      textAlign: 'right',
    },
  });
