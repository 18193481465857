import { createStyles, Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    indicator: {
      position: 'relative',
      background: '#EAEAEA',
      borderRadius: '8px',
      height: '12px',
      width: '100%',
      margin: '16px 0',
    },
    indicatorLine: {
      position: 'absolute',
      height: '12px',
      borderRadius: '8px',
      display: 'inline',
    },
    listItem: {
      display: 'flex',
      alignItems: 'baseline',
      marginBottom: '8px',
      fontWeight: 300,
      fontSize: '14px',
      maxWidth: '100%',
      '& a': {
        color: '#00A88E',
      },
    },
    marker: {
      width: '8px',
      height: '8px',
      marginRight: '8px',
      borderRadius: '50%',
      minWidth: '8px',
    },
    names: {
      flexGrow: 1,
    },
    values: {
      minWidth: '25px',
      textAlign: 'right',
      '& *': {
        verticalAlign: 'middle',
        display: 'inline-block',
      },
      '& svg': {
        marginRight: '8px',
      },
    },
  });

export default makeStyles(styles);
