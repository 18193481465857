import React, { useState, ChangeEvent, useMemo, useEffect } from 'react';
import { FormControl, FormLabel, IconButton } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'store/hook';
import { makeStyles } from '@material-ui/core/styles';
import isEqual from 'react-fast-compare';

import { usePermissions } from 'components/Permissions/hooks';
import { KLInput } from 'components/KLInput';
import { KLButton } from 'components/KLButton';
import { KLSelect } from 'components/KLSelect';
import { DateTime } from 'components/DateTime';
import { Loading } from 'components/Loading';
import { KLMultiSelect, KLMultiSelectType, KLMultiSelectValue } from 'components/KLMultiSelect';

import {
  closeDrawer,
  setDrawerData,
  listRequest,
  terminateSessionRequest,
  APIkeysState,
} from 'services/APIkeys/slice';

import { startSession, restartSession, updateSession } from 'services/APIkeys/api';

import { listRequest as tenantsListRequest } from 'services/tenants/slice';

import { addNotification } from 'services/system/slice';

import {
  RobotSessionStatus,
  RobotSessionResponse,
  RobotSessionItem,
  RobotUpdateSessionResponse,
  TenantRequest,
} from 'services/APIkeys/types';

import { Solution } from 'services/activation/types';

import { IS_HIDE_TENANTS } from 'global/environments';
import { CopyIcon } from 'assets/icons/CopyIcon';
import { copyText } from 'utils/help';
import { UserRoles } from '../../index';
import styles from './styles';

const statusNames = Object.values(RobotSessionStatus);
const useStyles = makeStyles(styles);

type APIKeysControlProps = {
  roles: UserRoles;
};

export const APIKeysControl: React.FC<APIKeysControlProps> = (props) => {
  const { roles } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation(['SettingsPage']);
  const [editOrganizationRobots] = usePermissions(['edit_organization_robots']);
  const { drawer } = useSelector((state: { APIkeys: APIkeysState }) => state.APIkeys);
  const [sessionName, setSessionName] = useState(drawer?.sessionName || '');
  const [token, setToken] = useState('');
  const [loadingToken, setTokenLoading] = useState(false);
  const clientId = useSelector((state) => state.auth.clientId);
  const { list: tenantsList, isLoading: loadingTenants } = useSelector((state) => state.tenants);
  const { solution } = useSelector((state) => state.activation);
  const isExpertSolution = solution === Solution.Expert;

  let initialTenant: TenantRequest[] = [];
  if (drawer?.tenants) {
    initialTenant = drawer.tenants.map((tenant) => ({ tenantId: tenant.tenantId }));
  }

  const [tenantValue, setTenantValue] = useState(initialTenant);
  const [wasChanges, setWasChanges] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(tenantsListRequest({}));
  }, [dispatch]);

  let initialRoleId = drawer?.roleId !== undefined ? drawer?.roleId : -1;
  if (initialRoleId === -1 && !isExpertSolution) {
    initialRoleId = 0;
  }
  const [roleId, setRoleId] = useState(initialRoleId);

  const closeDrawerHandler = () => {
    dispatch(closeDrawer());
  };

  const statSessionHandler = async () => {
    setTokenLoading(true);
    const { session, refreshToken }: RobotSessionResponse = await startSession(
      sessionName,
      tenantValue,
      roleId,
    );
    setToken(refreshToken);
    dispatch(setDrawerData(session));
    setTokenLoading(false);
    dispatch(listRequest({}));
  };

  const restartSessionHandler = async () => {
    setTokenLoading(true);
    const { stateToken, sessionId } = drawer as RobotSessionItem;
    const { session, refreshToken }: RobotSessionResponse = await restartSession(
      stateToken,
      sessionId,
    );
    setToken(refreshToken);
    dispatch(setDrawerData(session));
    dispatch(listRequest({}));
    setTokenLoading(false);
  };

  const sessionNameChangeHandler = (event: ChangeEvent) => {
    const sessionName = (event.target as HTMLInputElement).value;
    setSessionName(sessionName);
  };

  const onTerminateSession = () => {
    const { stateToken, sessionId } = drawer as RobotSessionItem;
    dispatch(terminateSessionRequest({ stateToken, sessionId }));
  };

  const updateSessionHandler = async () => {
    setIsLoading(true);
    const { stateToken, sessionId, sessionName } = drawer as RobotSessionItem;
    const { session }: RobotUpdateSessionResponse = await updateSession(
      stateToken,
      sessionId,
      sessionName,
      tenantValue,
      roleId,
    );
    dispatch(setDrawerData(session));
    setIsLoading(false);
    dispatch(closeDrawer());
    dispatch(listRequest({}));
    dispatch(
      addNotification({
        message: t('API.API settings were saved successfully'),
        options: { variant: 'success' },
      }),
    );
  };

  const tenantOptions = useMemo<KLMultiSelectValue[]>(
    () => tenantsList.map(({ name, tenantId }) => ({
      label: name as string,
      value: tenantId as string,
    })),
    [tenantsList],
  );

  const tenantSelectValue = useMemo<KLMultiSelectValue[]>(
    () => tenantOptions.filter(
      ({ value }) => tenantValue.findIndex((t) => t.tenantId === value) !== -1,
    ),
    [tenantOptions, tenantValue],
  );

  const tenantChangeHandler = (value: string[]) => {
    const newValue: TenantRequest[] = value.map((v) => ({ tenantId: v }));
    setTenantValue(newValue);
  };

  useEffect(() => {
    setWasChanges(!isEqual(tenantValue, initialTenant));
  }, [tenantValue, initialTenant]);

  useEffect(() => {
    setWasChanges(roleId !== initialRoleId);
  }, [roleId, initialRoleId]);

  if (loadingTenants) {
    return <Loading />;
  }

  const copyTagText = () => {
    copyText(dispatch, t, token);
  };

  return (
    <div className={classes.root}>
      <div className={classes.body}>
        <FormControl fullWidth className={classes.formBlock}>
          <FormLabel className={classes.label}>{t('API.Connection Name')} *</FormLabel>
          <KLInput
            name="sessionName"
            noMarginTop
            value={sessionName}
            onChange={sessionNameChangeHandler}
            disabled={drawer?.status !== undefined || !editOrganizationRobots}
          />
        </FormControl>

        {isExpertSolution && (
          <FormControl fullWidth className={classes.formBlock}>
            <FormLabel className={classes.label}>{t('API.User role')} *</FormLabel>
            <KLSelect
              items={Object.keys(roles).map((key) => ({ label: roles[+key], value: key }))}
              value={roleId}
              onChange={(e) => {
                setRoleId(Number(e.target.value));
                setWasChanges(true);
              }}
            />
          </FormControl>
        )}

        {!IS_HIDE_TENANTS && isExpertSolution && (
          <FormControl fullWidth className={classes.formBlock}>
            <FormLabel className={classes.label}>{t('Tenant')}</FormLabel>
            <KLMultiSelect
              key={tenantOptions.map((o) => o.value).toString()}
              value={tenantSelectValue}
              onChange={tenantChangeHandler}
              options={tenantOptions}
              type={KLMultiSelectType.checked}
              disabled={!editOrganizationRobots || (drawer?.status !== undefined && !!token)}
              allowSelectAll
              selectAllLabel={t('All tenants')}
            />
          </FormControl>
        )}

        {/* <FormControl fullWidth className={classes.formBlock}>
                    <FormLabel className={classes.label}>IP Address</FormLabel>
                    <KLInput
                        // id="emailField"
                        // type="email"
                        name="email"
                        noMarginTop
                    // disabled={isUpdateForm || isAdmin}
                    // value={emailField}
                    // onChange={emailChangeHandler}
                    // onBlur={() => touchedHandler('email')}
                    />
                    <FormLabel className={classes.labelBottom}>Пример написания: 1.1.1.1–2.2.2.2, 3.3.3.3</FormLabel>
                </FormControl> */}
        {drawer?.status !== undefined ? (
          <>
            <div>
              <FormControl fullWidth>
                <FormLabel className={classes.label}>
                  <span>{t('API.JWT token')}</span>
                  <IconButton
                    color="inherit"
                    size="small"
                    className={classes.copyIconButton}
                    onClick={copyTagText}
                  >
                    <CopyIcon />
                  </IconButton>
                </FormLabel>
                <div className={classes.textareaWrapper}>
                  {token ? null : (
                    <KLButton
                      className={classes.refresh}
                      color="primary"
                      onClick={restartSessionHandler}
                      disabled={!editOrganizationRobots}
                    >
                      {t('API.Refresh')}
                    </KLButton>
                  )}
                  <textarea
                    className={classes.textarea}
                    name="description"
                    readOnly
                    value={token}
                  />
                </div>
              </FormControl>
            </div>
            <div className={classes.disclaimer}>{t('API.A connection via new token')}</div>

            <div>
              <table>
                <tbody>
                  <tr>
                    <td className={classes.cell}>{t('API.Status')}</td>
                    <td className={classes.cell}>
                      {drawer ? t(`API.${statusNames[drawer.status]}`) : null}
                    </td>
                  </tr>
                  <tr>
                    <td className={classes.cell}>{t('API.Refresh Until')}</td>
                    <td className={classes.cell}>
                      <DateTime
                        timestamp={drawer?.expirationTime}
                        formatString="yyyy-MM-dd HH:mm"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className={classes.cell}>{t('API.Client ID')}</td>
                    <td className={classes.cell}>{clientId}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        ) : null}

        {drawer?.status === 3 ? <div>{t('API.Token expired')}</div> : null}

        {drawer?.status === undefined || drawer?.status === 3 ? (
          <div className={classes.action}>
            <KLButton
              disabled={
                !sessionName
                || sessionName.length < 3
                || roleId === -1
                || loadingToken
                || !editOrganizationRobots
              }
              variant="contained"
              color="primary"
              onClick={statSessionHandler}
            >
              {loadingToken ? (
                <div style={{ transform: 'scale(0.5)' }}>
                  <Loading />
                </div>
              ) : (
                t('API.Generate')
              )}
            </KLButton>
          </div>
        ) : null}

        {drawer?.status && drawer?.status !== 3 ? (
          <div className={classes.action}>
            <KLButton
              variant="outlined"
              color="primary"
              onClick={onTerminateSession}
              disabled={!editOrganizationRobots}
            >
              {t('API.Revoke')}
            </KLButton>
          </div>
        ) : null}
      </div>
      <div className={classes.footer}>
        {(drawer?.status !== undefined || !editOrganizationRobots) && (
          <KLButton
            className={classes.save}
            variant="contained"
            color="primary"
            onClick={updateSessionHandler}
            disabled={!wasChanges}
            isLoading={isLoading}
          >
            {t('API.Save')}
          </KLButton>
        )}
        <KLButton variant="outlined" color="primary" onClick={closeDrawerHandler}>
          {t('API.Close')}
        </KLButton>
      </div>
    </div>
  );
};
