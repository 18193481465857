import React, { ChangeEvent, FormEvent, useEffect, useMemo, useRef, useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  Typography,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import isEqual from 'react-fast-compare';
import { useSelector } from 'store/hook';
import { KLInput } from 'components/KLInput';
import { KLSelect } from 'components/KLSelect';
import { KLButton } from 'components/KLButton';
import { KLCheckBox } from 'components/KLCheckBox';
import { KLMultiSelect, KLMultiSelectType, KLMultiSelectValue } from 'components/KLMultiSelect';
import { addNotification } from 'services/system/slice';
import { useTranslation } from 'react-i18next';
import {
  closeSideDialog,
  createInviteRequest,
  updateActiveUserRequest,
  updateInvitedUserRequest,
} from 'services/user/slice';
import { UserListItem, TenantRequest } from 'services/user/types';
import { getChannelsRequest, getTelegramRequest } from 'services/notifications/slice';
import { listRequest as tenantsListRequest } from 'services/tenants/slice';
import { validateEmail } from 'utils/validation';
import { IS_HIDE_TENANTS } from 'global/environments';
import { UserRoles } from '../..';
import styles from './styles';

const useStyles = makeStyles(styles);

export const InviteForm = (props: { sideDialogData: UserListItem; roles: UserRoles }) => {
  const { sideDialogData, roles } = props;
  const { channels, isLoading, telegramStateToken, telegramActivated } = useSelector(
    (state) => state.notifications,
  );
  const { list: tenantsList } = useSelector((state) => state.tenants);
  const { isSubmitting } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const inviteForm = useRef<HTMLFormElement>(null);
  const classes = useStyles();
  const { t } = useTranslation(['SettingsPage']);

  const isUpdateForm = !!(sideDialogData && Object.entries(sideDialogData).length);
  const isActiveUser = sideDialogData && !!sideDialogData.activatedUserInfo;
  const isAdmin = sideDialogData && sideDialogData.roleId === 0;
  const userIdExists = sideDialogData?.activatedUserInfo?.userId;
  const isCanceled = sideDialogData?.itemType === 1 || sideDialogData?.itemType === 2;

  let initialEmail = '';
  let initialRole = -1;
  let initialDescription = '';
  let initialValidators = {
    email: { valid: false, touched: false },
    role: { valid: false, touched: false },
  };
  let initialTenant: TenantRequest[] = [];

  if (isUpdateForm) {
    initialEmail = (sideDialogData.activatedUserInfo && sideDialogData.activatedUserInfo.email)
      || (sideDialogData.inviteInfo && sideDialogData.inviteInfo.inviteEmail);
    initialRole = sideDialogData.roleId;
    initialDescription = sideDialogData.description;
    initialValidators = {
      email: { valid: true, touched: true },
      role: { valid: true, touched: true },
    };
    initialTenant = (
      (sideDialogData.activatedUserInfo && sideDialogData.activatedUserInfo.tenants)
      || (sideDialogData.inviteInfo && sideDialogData.inviteInfo.tenants)
    ).map((tenant) => ({ tenantId: tenant.tenantId }));
  }

  const [emailField, setEmailField] = useState(initialEmail);
  const [roleValue, setRoleValue] = useState(initialRole);
  const [description, setDescription] = useState(initialDescription);
  const [validators, setValidators] = useState(initialValidators as any);
  const [isValidForm, setIsValidForm] = useState(false);
  const [emailAvailable, setEmailAvailable] = useState(false);
  const [telegramAvailable, setTelegramAvailable] = useState(false);
  const [unsubscribeFromTelegram, setUnsubscribeFromTelegram] = useState(false);
  const [tenantValue, setTenantValue] = useState<TenantRequest[]>(initialTenant);

  const tenantOptions = useMemo<KLMultiSelectValue[]>(
    () => tenantsList.map(({ name, tenantId }) => ({
      label: name as string,
      value: tenantId as string,
    })),
    [tenantsList],
  );

  const tenantSelectValue = useMemo<KLMultiSelectValue[]>(
    () => tenantOptions.filter(
      ({ value }) => tenantValue.findIndex((t) => t.tenantId === value) !== -1,
    ),
    [tenantOptions, tenantValue],
  );

  const wasChanges = useMemo(
    () => initialEmail !== emailField
      || initialRole !== roleValue
      || initialDescription !== description
      || channels.email !== emailAvailable
      || channels.telegram !== telegramAvailable
      || !isEqual(initialTenant, tenantValue),
    [
      initialEmail,
      initialRole,
      initialTenant,
      initialDescription,
      emailField,
      roleValue,
      description,
      tenantValue,
      emailAvailable,
      telegramAvailable,
      channels,
    ],
  );

  useEffect(() => {
    dispatch(tenantsListRequest({}));
  }, [dispatch]);

  useEffect(() => {
    let res = true;
    for (const i in validators) {
      if (!validators[i].valid) {
        res = false;
        break;
      }
    }
    if (isValidForm !== res) setIsValidForm(res);
  }, [validators, isValidForm]);

  const submitHandler = (event: FormEvent) => {
    event.preventDefault();

    if (!sideDialogData) {
      return;
    }

    const roleId = roleValue;
    const userId = sideDialogData.activatedUserInfo && sideDialogData.activatedUserInfo.userId;
    const userStateToken = sideDialogData.activatedUserInfo && sideDialogData.activatedUserInfo.userStateToken;
    const inviteStateId = sideDialogData.inviteInfo && sideDialogData.inviteInfo.inviteStateId;
    if (isValidForm) {
      if (isUpdateForm) {
        if (isActiveUser) {
          dispatch(
            updateActiveUserRequest({
              userId,
              email: emailField,
              roleId,
              description,
              userStateToken,
              tenants: tenantValue,
              wasChanges,
              unsubscribeFromTelegram,
              telegramStateToken,
              channels: { email: emailAvailable, telegram: telegramAvailable },
            }),
          );
        } else {
          dispatch(
            updateInvitedUserRequest({
              userId,
              inviteEmail: emailField,
              roleId,
              inviteId: sideDialogData.inviteInfo.inviteId,
              inviteStateId,
              description,
              tenants: tenantValue,
            }),
          );
        }
      } else {
        dispatch(
          createInviteRequest({
            inviteEmail: emailField,
            roleId,
            description,
            tenants: tenantValue,
          }),
        );
      }
    } else {
      dispatch(
        addNotification({
          message: t('Please fill in the form'),
          options: { variant: 'error' },
        }),
      );
    }
  };

  const emailChangeHandler = (event: ChangeEvent) => {
    const email = (event.target as HTMLInputElement).value;
    setEmailField(email);
    if (validateEmail(email)) {
      setValidators({ ...validators, email: { ...validators.email, valid: true } });
    } else {
      setValidators({ ...validators, email: { ...validators.email, valid: false } });
    }
  };

  const roleChangeHandler = (event: any) => {
    const role = (event.target as HTMLInputElement).value;
    setRoleValue(+role);
    if (role) {
      setValidators({ ...validators, role: { ...validators.role, valid: true } });
    } else {
      setValidators({ ...validators, role: { ...validators.role, valid: false } });
    }
  };

  const tenantChangeHandler = (value: string[]) => {
    const newValue: TenantRequest[] = value.map((v) => ({ tenantId: v }));
    setTenantValue(newValue);
  };

  const touchedHandler = (validatorName: string) => {
    const newValidator = {
      ...validators,
      [validatorName]: { ...validators[validatorName], touched: true },
    };
    setValidators(newValidator);
  };

  useEffect(() => {
    if (userIdExists) {
      dispatch(getChannelsRequest(userIdExists));
      dispatch(getTelegramRequest(userIdExists));
    }
  }, []);

  useEffect(() => {
    setEmailAvailable(channels.email || false);
    setTelegramAvailable(channels.telegram || false);
  }, [isLoading]);

  return (
    <form name="InviteForm" ref={inviteForm} className={classes.form} noValidate>
      <Grid container direction="column" wrap="nowrap" spacing={2} className={classes.container}>
        <Grid item>
          <FormControl fullWidth error={!validators.email.valid && validators.email.touched}>
            <FormLabel className={classes.label} htmlFor="emailField">
              {t('Email')}
            </FormLabel>
            <KLInput
              id="emailField"
              type="email"
              name="email"
              placeholder={t('emailFieldPlaceholder')}
              noMarginTop
              disabled={isUpdateForm || isAdmin}
              value={emailField}
              onChange={emailChangeHandler}
              onBlur={() => touchedHandler('email')}
            />
            <FormHelperText className={classes.errorMessage}>
              {t('Please enter a valid email')}
            </FormHelperText>
          </FormControl>
        </Grid>

        {userIdExists ? (
          <>
            <Grid item>
              <FormControlLabel
                control={(
                  <KLCheckBox
                    color="primary"
                    checked={emailAvailable}
                    onChange={() => {
                      setEmailAvailable(!emailAvailable);
                    }}
                    id="user-list_add_email"
                  />
                )}
                label={t('Can get notifications via email')}
              />
            </Grid>

            <Grid item>
              <FormControlLabel
                control={(
                  <KLCheckBox
                    color="primary"
                    checked={telegramAvailable}
                    onChange={() => {
                      setTelegramAvailable(!telegramAvailable);
                    }}
                    id="user-list_add_telegram"
                  />
                )}
                label={t('Can get notifications via telegram')}
              />
            </Grid>
            {telegramStateToken && telegramActivated ? (
              <Grid item>
                <FormControlLabel
                  control={(
                    <KLCheckBox
                      color="primary"
                      onChange={() => {
                        setUnsubscribeFromTelegram(!unsubscribeFromTelegram);
                      }}
                      id="user-list_reset_telegram"
                    />
                  )}
                  label={t('Reset telegram')}
                />
              </Grid>
            ) : null}
          </>
        ) : null}

        <Grid item>
          <FormControl fullWidth error={!validators.role.valid && validators.role.touched}>
            <FormLabel className={classes.label}>{t('User role')}</FormLabel>
            <KLSelect
              items={Object.keys(roles).map((key) => ({ label: roles[+key], value: key }))}
              value={roleValue}
              onChange={roleChangeHandler}
              onBlur={() => touchedHandler('role')}
              displayEmpty
              id="user-list_add_role"
            />
            <FormHelperText className={classes.errorMessage}>
              {t('Please select a value')}
            </FormHelperText>
          </FormControl>
        </Grid>
        {!IS_HIDE_TENANTS && roleValue !== 0 && (
          <Grid item>
            <FormControl fullWidth>
              <FormLabel className={classes.label}>{t('Tenant')}</FormLabel>
              <KLMultiSelect
                key={tenantOptions.map((o) => o.value).toString()}
                value={tenantSelectValue}
                onChange={tenantChangeHandler}
                options={tenantOptions}
                type={KLMultiSelectType.checked}
                allowSelectAll
                selectAllLabel={t('All tenants')}
              />
            </FormControl>
          </Grid>
        )}
        <Grid item>
          <FormGroup>
            <FormControl>
              <FormLabel className={classes.label}>{t('Description')}</FormLabel>
              <textarea
                className={classes.description}
                name="description"
                value={description}
                onChange={(event) => setDescription(event.target.value)}
              />
            </FormControl>
          </FormGroup>
        </Grid>
        <Grid item>
          <Typography variant="body2">{t('Invited user should verify email address')}</Typography>
        </Grid>
        <Grid item className={classes.buttons}>
          <KLButton
            type="submit"
            color="primary"
            variant="contained"
            disabled={
              !isValidForm
              || isCanceled
              || (isValidForm && !wasChanges && !unsubscribeFromTelegram)
              || (roleValue > 0 && tenantValue.length === 0)
            }
            isLoading={isSubmitting}
            onClick={submitHandler}
          >
            {isUpdateForm ? t('Save') : t('Invite')}
          </KLButton>
          <KLButton
            color="primary"
            variant="outlined"
            onClick={() => dispatch(closeSideDialog())}
            disabled={isSubmitting}
          >
            {t('Cancel')}
          </KLButton>
        </Grid>
      </Grid>
    </form>
  );
};
