import { randomBytes } from 'crypto';
import { PayloadAction } from '@reduxjs/toolkit';
import { serialize } from 'cookie';
import decode from 'jwt-decode';
import { call, delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { stringifyUrl } from 'query-string';
import { load, save } from 'utils/storage';
import { activationExist } from 'services/activation/slice';
import { UIS_AUTH_URL, UIS_EU_AUTH_URL } from 'global/environments';
import { UNAUTHORIZED } from 'global/errors';
import { TelemetryStatsPeriodDays } from 'pages/StatisticsPage/TelemetryStats';
import { StatsFilterTenantIds } from 'pages/StatisticsPage';
import {
  changeClientId,
  checkLogin,
  loginCancel,
  loginError,
  loginRequest,
  loginStart,
  loginSuccess,
  logoutRequest,
  logoutSuccess,
  postLoginRequest,
  refreshRequest,
  setClientIdRequest,
  setRefreshChecking,
  setRegion,
  startRefreshCheck,
  userCreateRequest,
} from './slice';
import { getGetRegion, postGetUser, postPostLogin, postRefresh } from './api';
import { Action, LoginQuery, LoginResponse, Organization, TokenPayload } from './types';

const REFRESH_TIME = 'refreshTime';
const GET_USER_DATA_TRIES = 5;

const generateNonce = () => {
  const nonce = randomBytes(32).toString('hex');
  serialize('authNonce', nonce);
  return nonce;
};

const getClientId = (organizations: Organization[], selectedClientId: string | null) => {
  if (!organizations || !organizations.length) {
    return '';
  }
  if (selectedClientId && organizations.find((organization) => organization.organizationId === selectedClientId)) {
    return selectedClientId;
  }
  return organizations[0].organizationId;
};

const getRedirectUrl = (type = 'login') => {
  const { origin } = window.location;

  if (origin === 'http://localhost:3000') {
    return `http://localhost:8090/oidc/${type}`;
  }
  return `${origin}/oidc/${type}`;
};

const clearSessionData = () => {
  save('jwt', null);
  save('isSkippedUpdateNotice', null);
  save(REFRESH_TIME, null);
  save('clientId', null);
  save('idToken', null);
  save(StatsFilterTenantIds, null, true);
  save(TelemetryStatsPeriodDays, null, true);
};

const getRegion = async (): Promise<any> => {
  try {
    return await getGetRegion();
  } catch (error) {
    return { region: 'ru' };
  }
};

const uisUrl = (region: string) => (region === 'eu' ? UIS_EU_AUTH_URL : UIS_AUTH_URL);

const createLoginUrl = (nonce: string, region: string, createUser = false) => {
  const query: LoginQuery = {
    nonce,
    scope: 'openid profile email phone offline_access',
    /* eslint-disable @typescript-eslint/camelcase */
    client_id: 'MDR',
    response_type: 'code',
    response_mode: 'form_post',
    redirect_uri: getRedirectUrl(),
    /* eslint-enable */
  };

  if (createUser) {
    query.prompt = 'create';
  }

  return stringifyUrl({ url: `${uisUrl(region)}/authorize`, query });
};

function* goToLoginUIS(nonce: string) {
  const { region } = yield call(getRegion);
  yield put(setRegion(region));
  window.location.href = createLoginUrl(nonce, region);
}

function* goToUserCreationUIS() {
  const nonce = randomBytes(32).toString('hex');
  const { region } = yield call(getRegion);
  yield put(setRegion(region));
  window.location.href = createLoginUrl(nonce, region, true);
}

/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
const goToLogoutUIS = (idToken: string, region: string) => {
  window.location.href = stringifyUrl({
    url: `${uisUrl(region)}/endsession`,
    query: {
      /* eslint-disable @typescript-eslint/camelcase */
      // todo: what is "state"?
      // state: idToken,
      post_logout_redirect_uri: getRedirectUrl('logout'),
      /* eslint-enable */
    },
  });
};

function* login() {
  const nonce = generateNonce();
  yield call(goToLoginUIS, nonce);
}

const getSecret = (jwt: string) => {
  if (!jwt) {
    return null;
  }
  try {
    return decode<TokenPayload>(jwt).secret;
  } catch (e) {
    return null;
  }
};

function* setSession(authData: any) {
  const { emailConfirmed, userId, displayName, organizations, jwt, refreshTime, idToken } = authData;

  const secret = getSecret(jwt);
  const selectedClientId = load('clientId');

  if (!secret) {
    throw new Error('Invalid jwt');
  }

  const clientId = getClientId(organizations, selectedClientId);
  const currentOrganization = organizations.find(({ organizationId }: Organization) => organizationId === clientId);

  const roleName = currentOrganization ? currentOrganization.roleName : '';
  const isActiveOrganization = currentOrganization ? !!currentOrganization.socOrganizationId : false;

  save('clientId', clientId);
  save('jwt', jwt);
  save('idToken', idToken);
  save(REFRESH_TIME, refreshTime);

  if (clientId) {
    yield put(activationExist());
  }
  yield put(
    loginSuccess({
      displayName,
      userId,
      clientId,
      secret,
      organizations,
      refreshTime,
      emailConfirmed,
      idToken,
      roleName,
      isActiveOrganization,
    }),
  );

  const { isRefreshChecking } = yield select((store) => store.auth);
  if (!isRefreshChecking) {
    yield put(startRefreshCheck());
  }

  return jwt;
}

function* postLogin(action: PayloadAction<string>) {
  yield put(loginStart());
  try {
    const { error, ...authResult }: any = yield call(postPostLogin, action.payload);
    if (error) {
      throw new Error(error);
    }

    return yield call(setSession, authResult);
  } catch (error) {
    yield put(loginError(error.message || error));
    clearSessionData();
    yield put(logoutSuccess());

    return null;
  }
}

const tryGetUser = async (idToken: string, tries = 1): Promise<LoginResponse> => {
  try {
    return await postGetUser(idToken);
  } catch (error) {
    if (error === UNAUTHORIZED || tries < 2) {
      throw error;
    }
    return tryGetUser(idToken, tries - 1);
  }
};

function* refreshSession() {
  try {
    const authResult = yield call(postRefresh);
    yield call(setSession, authResult);
  } catch (error) {
    // else do nothing
  }
}

export function* check(isRefresh = false) {
  const { isAuthenticated } = yield select((store) => store.auth);
  const jwt = load('jwt');
  const idToken = load('idToken');

  if ((isAuthenticated && !isRefresh) || !jwt || !idToken) {
    clearSessionData();
    yield put(loginCancel());
    return; // user already authenticated;
  }
  const refreshTime = load(REFRESH_TIME);

  try {
    const userInfo = yield call(tryGetUser, idToken, GET_USER_DATA_TRIES);
    yield call(setSession, { jwt, refreshTime, idToken, ...userInfo });

    const {
      emailConfirmed,
      isActiveOrganization, // todo: better to use websocket notification
    } = yield select((store) => store.auth);

    if (!emailConfirmed || !isActiveOrganization) {
      yield call(refreshSession);
    }
  } catch (error) {
    if (error !== UNAUTHORIZED) {
      yield put(loginError(error.message || error));
    }

    clearSessionData();
    yield put(logoutSuccess());
  }
}

function* logout() {
  const { idToken } = yield select((store) => store.auth);
  clearSessionData();
  const { region } = yield call(getRegion);
  yield put(setRegion(region));
  yield call(goToLogoutUIS, idToken, region);
}

function* catchUnauthorized(action: Action) {
  if (action.payload === UNAUTHORIZED) {
    clearSessionData();
    yield put(logoutSuccess());
  }
}

function* startCheckRefreshSaga() {
  yield put(setRefreshChecking(true));
  while (true) {
    const { refreshTime, isLoading, isAuthenticated } = yield select((store) => store.auth);
    const isNeedToUpdateUisTokens = !refreshTime || refreshTime - Date.now() < 0;

    if (!isLoading && isAuthenticated && isNeedToUpdateUisTokens) {
      yield call(refreshSession);
    }

    yield delay(30000); // every 30 seconds
  }
}

function* setClientIdSaga(action: PayloadAction<string>) {
  const clientId = action.payload;
  const { organizations } = yield select((store) => store.auth);
  const { roleName } = organizations.find(({ organizationId }: Organization) => organizationId === clientId);
  save('clientId', clientId);
  yield put(changeClientId({ clientId, roleName }));
}

export const authSaga = [
  takeLatest(loginRequest.type, login),
  takeLatest(userCreateRequest.type, goToUserCreationUIS),
  takeLatest(postLoginRequest.type, postLogin),
  takeLatest(checkLogin.type, check),
  takeLatest(logoutRequest.type, logout),
  takeLatest(refreshRequest.type, refreshSession),
  takeLatest(startRefreshCheck.type, startCheckRefreshSaga),
  takeLatest(setClientIdRequest.type, setClientIdSaga),
  takeEvery('*', catchUnauthorized),
];
