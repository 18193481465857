import React from 'react';
import useStyles from 'pages/StatisticsPage/utils/useStyles';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'store/hook';
import { AssetStatus } from 'services/assets/types';
import { DonutChart } from '../DonutChart';
import { CustomLegendStatus } from '../CustomLegends';

const AssetsByStatusStats = () => {
  const classes = useStyles();
  const { t } = useTranslation(['StatisticsPage']);
  const {
    data: { assetsByStatus = [] },
  } = useSelector((state) => state.statistics);

  const { hideAbsentAssets } = useSelector((state) => state.settings);

  const assetByStatusData = assetsByStatus
    .map(({ status, count }: { status: number; count: number }) => {
      switch (status) {
        case AssetStatus.Absent:
          return { name: 'absent', value: count };
        case AssetStatus.Offline:
          return { name: 'offline', value: count };
        case AssetStatus.Ok:
          return { name: 'ok', value: count };
        case AssetStatus.Warning:
          return { name: 'warning', value: count };
        case AssetStatus.Critical:
          return { name: 'critical', value: count };
        default:
          return undefined;
      }
    })
    .filter((d: { name: string; value: number } | undefined): d is { name: string; value: number } => d !== undefined)
    .filter((d: { name: string; value: number }) => (hideAbsentAssets ? d.name !== 'absent' : true));

  return (
    <div className={classes.chartTile}>
      <h2 className={classes.subheader}>{t('Assets by status')}</h2>
      <DonutChart data={assetByStatusData} options={{}}>
        <CustomLegendStatus />
      </DonutChart>
    </div>
  );
};

export default AssetsByStatusStats;
