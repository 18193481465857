import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Box, Typography, useTheme, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'store/hook';
import { closeRequest, IncidentState } from 'services/incidents/slice';
import { IncidentIoc, IncidentResolution, IncidentStatus } from 'services/incidents/types';
import { AssetState } from 'services/assets/slice';
import { usePermissions } from 'components/Permissions/hooks';
import { DateTime } from 'components/DateTime';
import { KLTabs } from 'components/KLTabs';
import { Title } from 'components/Title';
import { Table } from 'components/Table';
import { LimitedText } from 'components/LimitedText';
import { KLChip } from 'components/KLChip';
import { KLButton } from 'components/KLButton';
import { SideDialog } from 'components/SideDialog';
import { Loading } from 'components/Loading';
import { showAssetStatus } from 'pages/AssetListPage';
import { IS_HIDE_ASSET_STATUSES } from 'global/environments';
import { getGeneralConfigRequest } from 'services/settings/slice';
import { CustomMarkdown } from '../CustomMarkdown';
import { MitreDescrLink, TACTICS, TECHNIQUES } from '../MitreDescrLink';
import { CloseIncidentFormState, ResolveIncidentForm } from '../CloseIncidentForm';
import { AssetTableRow, SummaryProps } from './types';

import styles from './styles';
import { IocDetailsDialog } from './components/IocDetailsDialog';

const useStyles = makeStyles(styles);

export const Summary: React.FC<SummaryProps> = props => {
  const { t } = useTranslation('Summary');
  const { details, onRowClickHandler } = props;
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [isOpenResolveIncidentDialog, setIsOpenResolveIncidentDialog] = useState(false);
  const { closeLoading, iocs } = useSelector((state: { incidents: IncidentState }) => state.incidents);
  const { list: assetList, isLoading: assetIsLoading } = useSelector((state: { assets: AssetState }) => state.assets);
  const { daysForStatusOffline } = useSelector(state => state.settings);
  const { userId } = useSelector(state => state.auth);
  const assetStatuses = React.useMemo(() => assetList.reduce<{[key: string]: number}>((acc, cur) => ({
    ...acc, [cur.assetId]: cur.status,
  }), {}), [assetList]);

  const [iocDetailsVisible, setIocDetailsVisible] = useState<boolean>(false);
  const [selectedIocId, setSelectedIocId] = useState<string>('');

  const [closeIncidentPermission] = usePermissions(['close_incident']);

  useEffect(() => {
    dispatch(getGeneralConfigRequest(userId));
  }, [userId, dispatch]);

  const handleResolveIncident = (state: CloseIncidentFormState) => {
    dispatch(closeRequest({
      ...state,
      incidentId: details.incidentId,
      message: t('Incident was successfully closed'),
    }));
  };

  const selectedIoc = useMemo(
    () => iocs?.find(ioc => ioc.data === selectedIocId) as IncidentIoc, [selectedIocId, iocs],
  );

  const truncateText = (text: string, maxChars: number) => (
    text.length > maxChars ? (
      <Tooltip title={text}>
        <span>{text.slice(0, maxChars)}...</span>
      </Tooltip>
    ) : (
      <span>{text}</span>
    )
  );

  return (
    <Box className={classes.root}>
      {iocDetailsVisible && selectedIoc && (
        <IocDetailsDialog
          ioc={selectedIoc}
          open={iocDetailsVisible}
          setIocDetailsVisible={setIocDetailsVisible}
        />
      )}
      <Box component="table" className={classes.summary}>
        <Box component="tbody">
          <Box component="tr">
            <Typography
              component="td"
              className={classes.label}
            >
              {t('Summary')}
            </Typography>
            <Typography component="td" className={classes.field}>
              <LimitedText text={details.summary} maxSize={256} />
            </Typography>
          </Box>
          <Box component="tr">
            <Typography
              component="td"
              className={classes.label}
            >
              {t('Priority')}
            </Typography>
            <Typography component="td" className={classNames(classes.field, classes.priority)}>
              {t(details.priority)}
            </Typography>
          </Box>
          <Box component="tr">
            <Typography
              component="td"
              className={classes.label}
            >
              {t('Status')}
            </Typography>
            <Typography component="td" className={classes.field}>
              <KLChip label={t(details.status)} borderColor="#FFAA59" outlined uppercase />
            </Typography>
          </Box>
          {details.statusDescription ? (
            <Box component="tr">
              <Typography
                component="td"
                className={classes.label}
              >
                {t('Status description')}
              </Typography>
              <Typography component="td" className={classes.fieldMd}>
                <CustomMarkdown
                  description={details.statusDescription}
                  withCache={`incidentStatus-${details.statusDescription}`}
                  classes={classes}
                />
              </Typography>
            </Box>
          ) : null}
          {details.resolution ? (
            <Box component="tr">
              <Typography
                component="td"
                className={classes.label}
              >
                {t('Resolution')}
              </Typography>
              <Typography component="td" className={classes.field}>
                {t(details.resolution)}
              </Typography>
            </Box>
          ) : null}
          <Box component="tr">
            <Typography
              component="td"
              className={classes.label}
            >
              {t('Created')}
            </Typography>
            <Typography component="td" className={classes.field}>
              <DateTime timestamp={details.creationTime} withTime />
            </Typography>
          </Box>
          {details.tenantName ? (
            <Box component="tr">
              <Typography
                component="td"
                className={classes.label}
              >
                {t('Tenant')}
              </Typography>
              <Typography component="td" className={classes.field}>
                {details.tenantName}
              </Typography>
            </Box>
          ) : null}
          {details.updateTime ? (
            <Box component="tr">
              <Typography
                component="td"
                className={classes.label}
              >
                {t('Updated')}
              </Typography>
              <Typography component="td" className={classes.field}>
                <DateTime timestamp={details.updateTime} withTime />
              </Typography>
            </Box>
          ) : null}
          {details.mitreTactics.length > 0 ? (
            <Box component="tr">
              <Typography
                component="td"
                className={classes.label}
              >
                {t('MITRE Tactics')}
              </Typography>
              <Typography component="td" className={classes.field}>
                {details.mitreTactics.map((item, idx) => (
                  <MitreDescrLink key={idx} type={TACTICS} name={item} />
                ))}
              </Typography>
            </Box>
          ) : null}
          {details.mitreTechniques.length > 0 ? (
            <Box component="tr">
              <Typography
                component="td"
                className={classes.label}
              >
                {t('MITRE Techniques')}
              </Typography>
              <Typography component="td" className={classes.field}>
                {details.mitreTechniques.map((item, idx) => (
                  <MitreDescrLink key={idx} type={TECHNIQUES} name={item} />
                ))}
              </Typography>
            </Box>
          ) : null}
          {details.detectionTechnology.length > 0 ? (
            <Box component="tr">
              <Typography
                component="td"
                className={classes.label}
              >
                {t('Detection technology')}
              </Typography>
              <Typography component="td" className={classes.field}>{details.detectionTechnology}</Typography>
            </Box>
          ) : null}
        </Box>
      </Box>

      <Title component="h2" classes={{ root: classes.affectedTitle }}>{t('Affected')}</Title>

      <KLTabs
        tabs={[
          {
            label: `${t('Affected assets')} (${details.affectedHosts.length})`,
            component: (
              <Table<AssetTableRow>
                onRowClick={onRowClickHandler}
                columns={[
                  {
                    title: t('Status'),
                    field: 'status',
                    render: ({ status }) => (
                      status || !assetIsLoading
                        ? showAssetStatus(
                          status,
                          daysForStatusOffline,
                        )
                        : <Loading size={16} />
                    ),
                    sorting: false,
                    hidden: IS_HIDE_ASSET_STATUSES,
                    cellStyle: {
                      lineHeight: 1,
                      verticalAlign: 'middle',
                    },
                  },
                  {
                    title: t('Asset name'),
                    field: 'host',
                    cellStyle: {
                      fontSize: theme.typography.pxToRem(14),
                    },
                  },
                  {
                    title: t('Asset ID'),
                    field: 'id',
                    cellStyle: {
                      fontSize: theme.typography.pxToRem(14),
                    },
                  },
                ]}
                data={details.affectedHosts.map(item => ({
                  status: assetStatuses[item.split(':')[1]],
                  host: item.split(':')[0],
                  id: item.split(':')[1],
                }))}
                count={details.affectedHosts.length}
                options={{
                  search: false,
                  showTitle: false,
                  toolbar: false,
                  sorting: false,
                  pageSize: Math.min(5, details.affectedHosts.length),
                }}
                isLoadable
              />
            ),
            disabled: details.affectedHosts.length === 0,
          },
          {
            label: `${t('Asset-based IOCs')} (${details.hostBasedIocs.length})`,
            component: (
              <Table<{ ioc: string }>
                columns={[
                  {
                    title: t('IOC'),
                    field: 'ioc',
                    cellStyle: {
                      fontSize: theme.typography.pxToRem(14),
                    },
                    render: ({ ioc }) => truncateText(ioc, 125),
                  },
                ]}
                data={details.hostBasedIocs.map(item => ({ ioc: item }))}
                count={details.hostBasedIocs.length}
                options={{
                  search: false,
                  showTitle: false,
                  toolbar: false,
                  sorting: false,
                  pageSize: Math.min(5, details.hostBasedIocs.length),
                }}
                isLoadable
                onRowClick={(e, data) => {
                  setIocDetailsVisible(true);
                  setSelectedIocId(data?.ioc.split(' ')[0] ?? '');
                }}
              />
            ),
            disabled: details.hostBasedIocs.length === 0,
          },
          {
            label: `${t('Network-based IOCs')} (${details.networkBasedIocs.length})`,
            component: (
              <Table<{ ioc: string }>
                columns={[
                  {
                    title: t('IOC'),
                    field: 'ioc',
                    cellStyle: {
                      fontSize: theme.typography.pxToRem(14),
                    },
                    render: ({ ioc }) => truncateText(ioc, 125),
                  },
                ]}
                data={details.networkBasedIocs.map(item => ({ ioc: item }))}
                count={details.networkBasedIocs.length}
                options={{
                  search: false,
                  showTitle: false,
                  toolbar: false,
                  sorting: false,
                  pageSize: Math.min(5, details.networkBasedIocs.length),
                }}
                onRowClick={(e, data) => {
                  setIocDetailsVisible(true);
                  setSelectedIocId(data?.ioc.split(' ')[0] ?? '');
                }}
                isLoadable
              />
            ),
            disabled: details.networkBasedIocs.length === 0,
          },
        ]}
        classes={{
          root: classes.affected,
          tab: classes.affectedTab,
          indicator: classes.affectedTabIndicator,
          panel: classes.affectedPanel,
        }}
        noBorder
      />

      {details.clientDescription ? (
        <Box className={classes.clientDescription}>
          <CustomMarkdown description={details.clientDescription} classes={classes} title={t('Client description')} />
        </Box>
      ) : null}

      {details.description ? (
        <Box className={classes.clientDescription}>
          <CustomMarkdown description={details.description} classes={classes} title={t('Description')} />
        </Box>
      ) : null}

      {closeIncidentPermission && details.status !== IncidentStatus.Closed && (
        <Box>
          <Title component="h2" classes={{ root: classes.actionTitle }}>{t('Actions')}</Title>
          <Typography variant="body1" className={classes.actionDescription}>{t('Use this function')}</Typography>
          <KLButton
            color="primary"
            variant="outlined"
            onClick={() => {
              setIsOpenResolveIncidentDialog(true);
            }}
          >
            {t('Close incident')}
          </KLButton>
          <SideDialog<CloseIncidentFormState>
            state={{ comment: '', resolution: IncidentResolution.TruePositive }}
            open={isOpenResolveIncidentDialog}
            title={t('Close incident')}
            onClose={() => {
              setIsOpenResolveIncidentDialog(false);
            }}
            onCancel={() => {
              setIsOpenResolveIncidentDialog(false);
            }}
            cancelText={t('Cancel')}
            onSubmit={handleResolveIncident}
            submitText={t('Close')}
            loading={closeLoading}
            render={(state, setState) => (
              <ResolveIncidentForm
                state={state}
                setState={setState}
                isLoading={closeLoading}
              />
            )}
          />
        </Box>
      )}
    </Box>
  );
};
