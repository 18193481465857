import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { telemetryStatsRequest } from 'services/statistics/slice';
import { useSelector } from 'store/hook';
import { Loading } from 'components/Loading';
import { RootState } from 'store/reducer';
import { listRequest } from 'services/tenants/slice';
import { save, load } from 'utils/storage';
import styles from './styles';

const useStyles = makeStyles(styles);
const daysSince2000 = (new Date().getFullYear() - 2000) * 365;
const ranges = [
  { key: 'Yesterday', days: 1 },
  { key: '7 days', days: 7 },
  { key: '30 days', days: 30 },
  { key: '90 days', days: 91 },
  { key: '180 days', days: 182 },
  { key: '1 year', days: 365 },
  { key: 'All time', days: daysSince2000 },
] as const;

export const TelemetryStatsPeriodDays = 'TelemetryStatsPeriodDays';

const BILLION = 10 ** 9;
const TRILLION = 10 ** 12;
const QUADRILLION = 10 ** 15;

function triplets(n: number): string {
  if (n < 1000) {
    return n.toString();
  }
  const s = n.toString();
  let result = '';
  for (let i = 1; i <= s.length; i++) {
    if (i % 3 === 1) {
      result = ` ${result}`;
    }
    result = s[s.length - i] + result;
  }
  return result;
}

function formatNumber(n: number, t: (s: string, p?: any) => string): string {
  if (n <= BILLION) {
    return triplets(n);
  }
  if (n > BILLION && n < TRILLION) {
    return t('n billions', { n: triplets(Math.round(n / BILLION)) });
  }

  if (n >= TRILLION && n < QUADRILLION) {
    return t('n trillions', { n: triplets(Math.round(n / TRILLION)) });
  }
  return t('quadrillion');
}

export function TelemetryStats() {
  const styles = useStyles();
  const { t } = useTranslation(['StatisticsPage']);
  const dispatch = useDispatch();
  const { clientId, userId, roleName, isActiveOrganization } = useSelector((state) => state.auth);
  const [periodDays, setPeriodDays] = useState(load(TelemetryStatsPeriodDays, true) || 30);
  const {
    telemetryStats,
    telemetryStatsDaysCount,
    telemetryStatsLoading,
    telemetryStatsError,
    filterTenantIds,
  } = useSelector(({ statistics }: RootState) => statistics);

  useEffect(() => {
    dispatch(telemetryStatsRequest({ dayRange: periodDays, tenantIds: filterTenantIds }));
  }, [dispatch, filterTenantIds, periodDays]);

  useEffect(() => {
    if (!userId || !clientId) {
      return;
    }
    // TODO: Из-за того что обновляются параметы не одновременно, происходит 2 запроса
    dispatch(listRequest({ clientId, userId }));
  }, [clientId, userId, roleName, isActiveOrganization, dispatch]);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <h2 className={styles.subheader}>{t('Telemetry statistics')}</h2>
      </div>
      {telemetryStatsError === null && (
        <div className={styles.rangeButtonGroup}>
          {ranges.map((period) => (
            <button
              key={period.key}
              className={classNames(styles.rangeButton, {
                [styles.rangeButtonSelected]: period.days === telemetryStatsDaysCount,
              })}
              onClick={() => {
                setPeriodDays(period.days);
                save(TelemetryStatsPeriodDays, period.days, true);
              }}
            >
              {t(`Telemetry statistics range.${period.key}`)}
            </button>
          ))}
          <div className={styles.utcNotice}>{t('Data shown since notice timezone')}</div>
        </div>
      )}
      {telemetryStatsError !== null && (
        <div className={styles.noDataMessage}>{t('No data message')}</div>
      )}
      {telemetryStatsLoading && (
        <div className={styles.loadingWrap}>
          <Loading />
        </div>
      )}
      {telemetryStats !== null && telemetryStatsError === null && (
        <>
          <table className={styles.table}>
            <tbody>
              <tr>
                <td className={classNames(styles.tableCell, styles.tableCellEvents)}>
                  {formatNumber(telemetryStats.eventsCount, t)}
                </td>
                <td className={classNames(styles.tableCell)}>{t('Telemetry events')}</td>
              </tr>
              <tr>
                <td className={classNames(styles.tableCell, styles.tableCellSuspiciousEvents)}>
                  {formatNumber(telemetryStats.tagEventsSum, t)}
                </td>
                <td className={classNames(styles.tableCell)}>{t('Suspicious events')}</td>
              </tr>
              <tr>
                <td className={classNames(styles.tableCell, styles.tableCellAlerts)}>
                  {formatNumber(telemetryStats.alertsCount, t)}
                </td>
                <td className={classNames(styles.tableCell)}>{t('Alerts')}</td>
              </tr>
              <tr>
                <td className={classNames(styles.tableCell, styles.tableCellIncidents)}>
                  {formatNumber(telemetryStats.incidentsCount, t)}
                </td>
                <td className={classNames(styles.tableCell)}>
                  <Link to="/incidents" className={styles.incidentsLink}>
                    {t('Incidents')}
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
          <div className={styles.dataSinceNotice}>
            {t('Detection rules triggered')}: {telemetryStats.huntsCount}
          </div>
          <div className={styles.dataSinceNotice}>{t('Data shown since notice')}</div>
        </>
      )}
    </div>
  );
}
