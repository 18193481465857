import React, { FC, useMemo } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { listRequest, validateRequest, deleteRequest } from 'services/tenants/slice';
import { TenantItem } from 'services/tenants/types';
import { useSelector } from 'store/hook';
import { Table } from 'components/Table';
import { KLButton } from 'components/KLButton';
import { AddIcon } from 'assets/icons/AddIcon';
import { usePermissions } from 'components/Permissions/hooks';
import { DateTime } from 'components/DateTime';
import { useConfirmation } from 'components/Confirmation';
import { fixTimezoneOffset } from 'utils/date';
import { DeleteIcon } from 'assets/icons/DeleteIcon';
import { load, save } from 'utils/storage';
import { StatsFilterTenantIds } from 'pages/StatisticsPage';
import styles from './styles';

const ROOT_TENANT_ID = '-';

const useStyles = makeStyles(styles);

export const Tenants: FC = () => {
  const { list, isLoading } = useSelector((state) => state.tenants);
  const theme = useTheme();
  const classes = useStyles(theme);
  const dispatch = useDispatch();
  const { openConfirmation, closeConfirmation } = useConfirmation();

  // TODO: Remove when root tenant become real one
  const listWihtoutRoot = useMemo(
    () => list.filter((tenant) => tenant.tenantId !== ROOT_TENANT_ID),
    [list],
  );

  const { t } = useTranslation(['SettingsPage']);

  const [editOrganizationUsersPerm] = usePermissions(['edit_organization_users']);

  const onRowClickHandler = (event: any, rowData: TenantItem | undefined): void => {
    dispatch(validateRequest(rowData));
  };

  const onAddTentant = () => {
    dispatch(validateRequest({}));
  };

  const onDeleteTenant = (event: any, data: any) => {
    if (!data) return;
    const { tenantId } = data;
    openConfirmation({
      title: t('Tenants.Confirm tenant deletion'),
      content: t('Tenants.Are you sure that you want to delete this tenant'),
      confirmText: t('Delete'),
      dismissText: t('Cancel'),
      onConfirm: () => {
        dispatch(deleteRequest(tenantId));
        closeConfirmation();
        const tenantIdsFromStorage = load(StatsFilterTenantIds, true);
        if (tenantIdsFromStorage?.length) {
          const filteredTenantIds = tenantIdsFromStorage.filter((id: string) => id !== tenantId);
          save(StatsFilterTenantIds, filteredTenantIds, true);
        }
      },
    });
  };

  return (
    <Table<TenantItem>
      data={listWihtoutRoot ?? []}
      count={listWihtoutRoot?.length}
      action={listRequest}
      actions={
        editOrganizationUsersPerm
          ? [
            {
              icon: () => <DeleteIcon className={classes.deleteIcon} />,
              tooltip: t('API.Delete key'),
              position: 'row',
              onClick: onDeleteTenant,
            },
          ]
          : []
      }
      extraActions={
        editOrganizationUsersPerm
          ? [
            <KLButton
              color="primary"
              variant="contained"
              startIcon={<AddIcon fill="#FFFFFF" />}
              onClick={onAddTentant}
              id="tenants_add"
            >
              {t('Add')}
            </KLButton>,
          ]
          : []
      }
      onRowClick={onRowClickHandler}
      columns={[
        {
          searchable: false,
          sorting: false,
          width: `${theme.spacing(4)}px`,
        },

        {
          title: t('Tenants.Name'),
          field: 'name',
          sorting: false,
          searchable: false,
        },

        {
          title: t('Tenants.Blob status'),
          field: 'isActive',
          sorting: false,
          searchable: false,
          render: ({ isActive }) => (isActive ? 'Active' : 'Inactive'),
        },

        {
          title: t('Tenants.Number of assets'),
          field: 'assetsCount',
          sorting: false,
          searchable: false,
          render: ({ assetsCount }) => `${assetsCount}`,
        },

        {
          title: t('Tenants.Description'),
          field: 'description',
          sorting: false,
          searchable: false,
        },

        {
          title: t('Tenants.Created date'),
          field: 'licenseStartDate',
          sorting: false,
          searchable: false,
          render: ({ licenseStartDate }) => (licenseStartDate ? (
            <DateTime timestamp={fixTimezoneOffset(licenseStartDate)} formatString="yyyy-MM-dd" />
          ) : null),
        },

        {
          title: t('Tenants.Expiration date'),
          field: 'expirationDate',
          sorting: false,
          searchable: false,
          render: ({ licenseEndDate }) => (licenseEndDate ? (
            <DateTime timestamp={fixTimezoneOffset(licenseEndDate)} formatString="yyyy-MM-dd" />
          ) : null),
        },
      ]}
      title={t('Tenants.Tenants')}
      isLoading={isLoading}
      options={{
        showTextRowsSelected: false,
        paging: false,
        actionsColumnIndex: -1,
        tableLayout: 'auto',
      }}
      locator="tenants"
      uniqueId="tenantId"
    />
  );
};
