import { takeLatest, put, call, all } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { addNotification } from 'services/system/slice';
import { load, save } from 'utils/storage';
import { COLUMNS } from 'global/storage';
import { NOT_FOUND, INCIDENT_REVOKED, ACCESS_DENIED } from 'global/errors';
import {
  listRequest,
  listStart,
  listSuccess,
  listCount,
  listSort,
  detailsRequest,
  detailsStart,
  detailsSuccess,
  markAsRead,
  markAsReadRequest,
  newCountRequest,
  setUnreadCount,
  createRequest,
  createStart,
  createSuccess,
  setColumns,
  columnsGetRequest,
  columnsSetRequest,
  autoResponseGetRequest,
  autoResponseSetRequest,
  startAutoResponse,
  setAutoResponse,
  closeRequest,
  closeStart,
  closeSuccess,
  closeError,
  error,
  setIocs,
} from './slice';
import * as api from './api';
import {
  IncidentListSagaPayload,
  IncidentMarkAsReadPayload,
  IncidentCreatePayload,
  IncidentColumnsPayload,
  IncidentClosePayload,
  IncidentAutoResponse,
  IncidentAutoResponsePayload,
} from './types';

function* fetchIncidentList(action: PayloadAction<IncidentListSagaPayload>): any {
  yield put(listStart());

  try {
    const [items, count] = yield all([
      call(api.postIncidentList, action.payload),
      call(api.postIncidentCount, action.payload),
    ]);
    yield put(listSuccess(items));
    yield put(listCount(count));
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

function* fetchIncidentDetails(action: PayloadAction<string>): any {
  yield put(detailsStart());

  try {
    const incidentIocs = yield call(api.postIncidentIocs, action.payload);
    const details = yield call(api.postIncidentDetails, action.payload);
    yield put(setIocs(incidentIocs));
    yield put(detailsSuccess(details));
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));

    if (message !== NOT_FOUND && message !== INCIDENT_REVOKED && message !== ACCESS_DENIED) {
      yield put(addNotification({ message, options: { variant: 'error' } }));
    }
  }
}

function* fetchIncidentMarkAsRead(action: PayloadAction<IncidentMarkAsReadPayload>): any {
  try {
    yield call(api.postMarkAsRead, action.payload);
    yield put(markAsRead({ idField: 'incidentId', entityIds: action.payload.entityIds }));
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

function* fetchIncidentNewCount(): any {
  try {
    const newCount = yield call(api.postIncidentNewCount);
    yield put(setUnreadCount(newCount));
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

function* fetchIncidentCreate(action: PayloadAction<IncidentCreatePayload>): any {
  yield put(createStart());

  try {
    const { message, filtersSet, ...rest } = action.payload;
    const details = yield call(api.postIncidentCreate, rest);
    yield put(createSuccess({ ...details, filtersSet }));
    yield put(listSort({ field: 'updateTime', order: 'desc' }));
    yield put(detailsSuccess(details));
    yield put(addNotification({ message, options: { variant: 'success' } }));
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

function* getIncidentColumns(): any {
  // TODO: Remove this after backend columns config saving will be done
  const columns = yield load(COLUMNS);
  yield put(setColumns(columns?.incidents || null));
}

function* setIncidentColumns(action: PayloadAction<IncidentColumnsPayload>): any {
  // TODO: Remove this after backend columns config saving will be done
  const columns = yield load(COLUMNS);
  yield save(COLUMNS, { ...columns, incidents: action.payload });
}

function* getIncidentAutoResponse(): any {
  try {
    const autoResponse: IncidentAutoResponse = yield call(api.getIncidentAutoResponse);
    yield put(setAutoResponse(autoResponse));
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

function* setIncidentAutoResponse(action: PayloadAction<IncidentAutoResponsePayload>): any {
  yield put(startAutoResponse());
  try {
    const { message } = action.payload;
    yield call(api.setIncidentAutoResponse, action.payload);
    const autoResponse: IncidentAutoResponse = yield call(api.getIncidentAutoResponse);
    yield put(setAutoResponse(autoResponse));
    yield put(addNotification({ message, options: { variant: 'success' } }));
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

function* closeIncident(action: PayloadAction<IncidentClosePayload>) {
  yield put(closeStart());
  try {
    const { message, ...rest } = action.payload;
    yield call(api.closeIncident, rest);
    yield put(closeSuccess(action.payload));
    yield put(addNotification({ message, options: { variant: 'success' } }));
  } catch (err) {
    const message = err.message || err;
    yield put(closeError(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

export const incidentSaga = [
  takeLatest(listRequest.type, fetchIncidentList),
  takeLatest(detailsRequest.type, fetchIncidentDetails),
  takeLatest(markAsReadRequest.type, fetchIncidentMarkAsRead),
  takeLatest(newCountRequest.type, fetchIncidentNewCount),
  takeLatest(createRequest.type, fetchIncidentCreate),
  takeLatest(columnsGetRequest.type, getIncidentColumns),
  takeLatest(columnsSetRequest.type, setIncidentColumns),
  takeLatest(autoResponseGetRequest.type, getIncidentAutoResponse),
  takeLatest(autoResponseSetRequest.type, setIncidentAutoResponse),
  takeLatest(closeRequest.type, closeIncident),
];
